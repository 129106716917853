import * as React from "react";
import styled from "@emotion/styled";
import {withTheme} from "@emotion/react";
import {AppBar as MuiAppBar, Box, Button, Grid, IconButton as MuiIconButton, Toolbar, Typography,} from "@mui/material";

import {Menu as MenuIcon} from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";
import useAppSelector from "../../hooks/useAppSelector";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Logo from "../../assets/images/Logo-Icon-Regular.svg";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
const InfinityLogo = styled.img`
  height: 4rem;
  width: 4rem;
`;
type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const user = useAppSelector((state) => state.userReducer.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid
              item
              display="flex"
              alignItems="center"
              onClick={() => {
                navigate("/solutions");
              }}
              sx={{ cursor: "pointer" }}
            >
              <Typography
                variant="h3"
                sx={{
                  margin: "0 10px 0 20px",
                  color: "#000",
                  letterSpacing: "3px",
                }}
              >
                Infinity Web
              </Typography>
              <InfinityLogo src={Logo} alt="Infinity Web" />
            </Grid>
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              {user && Object.keys(user).length > 0 ? (
                <NavbarUserDropdown />
              ) : (
                <Box display="flex">
                  <Button
                    onClick={() => {
                      navigate("/sign-in");
                      dispatch({
                        type: "PREVIOUS_ROUTE_CHECK",
                        payload: true,
                      });
                    }}
                    size="large"
                    variant="contained"
                  >
                    Sign In
                  </Button>
                  <Button
                    onClick={() => navigate("/sign-up")}
                    size="large"
                    variant="contained"
                    sx={{ margin: "0 20px" }}
                  >
                    Sign Up
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
