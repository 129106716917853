import styled from "@emotion/styled";
import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import {spacing, SpacingProps} from "@mui/system";
import {CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import StripBanner from "../../assets/images/Stripe.png";
import useAppSelector from "../../hooks/useAppSelector";
import IStripCustomer from "../../models/StripeCustomer.model";
import URL from "../../services/Apis";
import {del, get, put} from "../../services/AxiosRequestMethods";
import CollapseOption from "../CollapseOption";
import BillingHistory from "./BillingHistory";
import IStripePaymentMethod from '../../models/StripePayment.model';

const Card = styled(MuiCard)(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

function Billing() {
  const stripe = useStripe();
  const elements = useElements();
    const [isActiveChangePassword, setIsActiveChangePassword] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<IStripePaymentMethod[]>([]);
    const [editId, setEditId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const user = useAppSelector((state) => state.userReducer.user);

    useEffect(() => {
        listPaymentMethods()
    }, [])

    const listPaymentMethods = () => {
        get<IStripePaymentMethod[]>(URL.stripe.listPaymentMethod, user?.token).then(res => {
            setEditId("")
            setPaymentMethods(res)
        }).catch((err: Error) => {
            console.error({err})
        })
    }

    const createCustomer = async (response: IStripCustomer) => {

        const testNumberElement = elements?.getElement(CardNumberElement);
        const testExpiryElement = elements?.getElement(CardExpiryElement);
        const testCvcElement = elements?.getElement(CardCvcElement);

        let data = {
      id: user?.id,
      stripeCustomerId: response?.id,
    };

    await put(URL.Users.update, data, {
        headers: {
            Authorization: `${user?.token}`,
        },
    })
        .then((res: any) => {
            listPaymentMethods()
            if (res?.message) {
                if (paymentMethods) {
                    toast.success(
                        `${paymentMethods[0].card!.last4} successfully added.`
            );
          }
        }
        setLoading(false);
        testNumberElement?.clear();
        testExpiryElement?.clear();
        testCvcElement?.clear();
      })
      .catch((err: Error) => {
        setLoading(false);
        if (err.message) {
          toast.error(err.message);
        }
        console.error(err);
      });
  };

  const handleStripeUpdate = async () => {
    setLoading(true);
    const testNumberElement = elements?.getElement(CardNumberElement);
    if (testNumberElement) {
      // Create Payment Method
      await stripe
        ?.createPaymentMethod({
          type: "card",
          card: testNumberElement,
        })
        .then(async (res: any) => {
          if (res.error) {
            toast.error(res?.error?.message);
            setLoading(false);
            return;
          }
          let data = {
            id: user?.id,
            paymentMethodId: res?.paymentMethod?.id,
            force: true,
          };
          // customer create
          await put(URL.stripe.userPaymentMethod, data, {
            headers: {
              Authorization: `${user?.token}`,
            },
          })
            .then((response: IStripCustomer | any) => {
              createCustomer(response);
            })
            .catch((err) => {
              console.error("api error", err);
              if (err.message) {
                toast.error(err.message);
              }
              setLoading(false);
            });
        });
    }
  };

  const removeCard = async (paymentMethodId: string) => {
    setEditId(paymentMethodId);
    await del(URL.stripe.delPaymentMethod, user?.token, { paymentMethodId })
      .then(() => {
          listPaymentMethods()
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Box display="flex" alignItems="center" height="4rem">
          <Typography variant="h6">My Cards</Typography>
          <Box sx={{ height: "95px" }}>
              <img alt="Powered by Stripe" src={StripBanner} style={{width: "100%", height: "100%"}}/>
          </Box>
        </Box>
        <Grid container gap={4}>
          {paymentMethods.length > 0 &&
            paymentMethods.map((payment: IStripePaymentMethod, index: number) => (
              <Grid
                key={index}
                borderRadius={2}
                p={2}
                border={"0.5px solid rgba(0,0,0,0.3)"}
                item
                my={5}
                md={5}
                sm={8}
                xs={10}
              >
                <Typography variant="h5">
                  Card Number: **** **** **** {payment?.card?.last4}
                </Typography>
                  {editId === payment.id ? (
                      <Stack mt={3} alignItems="center">
                          <CircularProgress/>{" "}
                      </Stack>
                  ) : (
                      <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          mt={3}
                          onClick={() => removeCard(payment.id)}
                  >
                    Remove Card
                  </Button>
                )}
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="h6" gutterBottom mb={2}>
          Billing
        </Typography>
        <CollapseOption
          title={`Add new Card`}
          description={`Add Your Credit Card Information.`}
          button={`Add Credit Card`}
          active={isActiveChangePassword}
          onChange={setIsActiveChangePassword}
        >
          <Box sx={{ width: "40vw" }}>
            <Typography sx={{ mb: 2 }} variant="h6" component="h6">
              Credit Card Information
            </Typography>
            <Box>
              <form>
                <Grid container sx={{ mt: 2 }}>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sx={{
                      border: "1px solid rgba(0,0,0,0.2)",
                      py: 2,
                      pl: 2,
                      my: 2,
                      borderRadius: "3px",
                    }}
                  >
                    <CardNumberElement
                      options={{
                        showIcon: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid
                        xs={12}
                        md={5.5}
                        sx={{
                          border: "1px solid rgba(0,0,0,0.2)",
                          py: 2,
                          pl: 2,
                          borderRadius: "3px",
                        }}
                      >
                        <CardExpiryElement />
                      </Grid>
                      <Grid
                        xs={12}
                        md={5.5}
                        sx={{
                          border: "1px solid rgba(0,0,0,0.2)",
                          py: 2,
                          pl: 2,
                          borderRadius: "3px",
                        }}
                      >
                        <CardCvcElement />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
            <Grid item xs={12} md={10}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => setIsActiveChangePassword(false)}
                  variant="outlined"
                  color="info"
                >
                  Cancel
                </Button>
                {loading ? (
                  <CircularProgress sx={{ ml: "0.5rem" }} />
                ) : (
                  <Button
                    onClick={handleStripeUpdate}
                    sx={{ ml: "0.5rem" }}
                    variant="contained"
                  >
                    Add New Card
                  </Button>
                )}
              </Box>
            </Grid>
          </Box>
        </CollapseOption>
      </CardContent>
      <BillingHistory />
    </Card>
  );
}

export default Billing;
