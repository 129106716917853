import styled from "@emotion/styled";
import {Cached, ExpandLess, ExpandMore} from "@mui/icons-material";
import {
    Box,
    Button,
    Collapse,
    Divider,
    Grid,
    IconButton,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import TaskProgress from "../../components/TaskProgress/Index";
import useAppSelector from "../../hooks/useAppSelector";
import UsageModel from "../../models/usage.model";
import URL from "../../services/Apis";
import {get} from "../../services/AxiosRequestMethods";
import {PurchaseReducerType, SolutionReducerType} from "../../redux/reducers/types";
import TabModel from '../../models/StrapiModels/tab.model'
import AnchorModel from '../../models/StrapiModels/anchor.model'

const BottomBar = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25%;
  margin-bottom: 1rem;
`;
const SideTabs = styled(Box)`
  position: relative;
  min-width: 25%;
  max-width: 25%;
  width: 25%;
  // background-color: #f1f1f1;
  background-color: ${(props) => props.theme.sidebar.background};
  border-radius: 10px;
  max-height: 55vh;
  min-height: 55vh;
`;

const Tabs = styled(Box)`
  height: 75%;
  overflow-y: auto;
  padding: 10px 5px 0 15px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 15px;
    border-radius: 10px;
    background-color: ${(props) => props.theme.sidebar.background};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 10px;
  }
`;

const SingleTab = styled(Button)`
  justify-content: flex-start;
  text-align: left;
  color: #fff;
  font-size: 14px;
  padding: 20px 20px 20px 20px;
  /* margin: 5px; */

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

interface Props {
    tabContent: TabModel[]
    onItemsClick: (anchor: AnchorModel, index: number) => void
    onSubItemsClick: (dropdown: AnchorModel, anchorIndex: number, dropdownIndex: number) => void
}

function SideTabComp(props: Props) {
    props.tabContent.forEach(tc => tc.attributes.anchors?.data.sort((a, b) => a.attributes.position - b.attributes.position))
    props.tabContent.forEach(tc => tc.attributes.drop_downs?.data.sort((a, b) => a.attributes.position - b.attributes.position))
    props.tabContent.forEach(tc => tc.attributes.drop_downs?.data.forEach(dd => dd.attributes.anchors?.data.sort((a, b) => a.attributes.position - b.attributes.position)))

    const [open, setOpen] = useState(true);
    const [usage, setUsage] = useState<UsageModel | null>(null);
    const solutionReducer = useAppSelector<SolutionReducerType>((state) => state.solutionReducer)
    const purchaseReducer = useAppSelector<PurchaseReducerType>((state) => state.purchaseReducer)
    const user = useAppSelector((state) => state.userReducer.user);

    const handleClick = () => setOpen(!open)

    const fetchUsage = async () => {
        await get<UsageModel[]>(URL.Usage.list(purchaseReducer.purchase?.id, new Date()), user?.token)
            .then(usage => setUsage(usage[0]))
            .catch((err: Error) => console.error({err}))
    }

    const getSideTabs = () => {
        return props.tabContent?.map((script, i) => (
            <React.Fragment key={i}>
                {script.attributes.anchors?.data.map((anchor: any, idx: number) => (
                    <SingleTab
                        key={idx}
                        fullWidth
                        onClick={() => {
                            props.onItemsClick(anchor, idx)
                        }}
                    >
                        {anchor.attributes.title}
                    </SingleTab>
                ))}
                {script.attributes.drop_downs?.data.map(
                    (variation: any, index: number) => (
                        <React.Fragment key={index}>
                            <ListItemButton onClick={handleClick}>
                                <ListItemText
                                    sx={{fontSize: "14px", ml: 3, color: "#fff"}}
                                    primary={variation?.attributes?.title}
                                />
                                {open ? (
                                    <ExpandLess style={{color: "#fff"}}/>
                                ) : (
                                    <ExpandMore style={{color: "#fff"}}/>
                                )}
                            </ListItemButton>

                            {variation.attributes.anchors.data.map(
                                (dropDown: any, idx: number) => {
                                    return (
                                        <Collapse
                                            key={idx}
                                            in={open}
                                            timeout="auto"
                                            sx={{ml: 3}}
                                            unmountOnExit
                                        >
                                            <SingleTab
                                                fullWidth
                                                onClick={() => {
                                                    props.onSubItemsClick(dropDown, index, idx)
                                                }}
                                            >
                                                {dropDown.attributes.title}
                                            </SingleTab>
                                        </Collapse>
                                    );
                                }
                            )}
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        ))
    }

    return (
        <SideTabs>
            <Tabs>{getSideTabs()}</Tabs>
            <BottomBar>
                <Divider/>
                <Grid my={4} container justifyContent={"center"}>
                    <Grid item xs={10}>
                        <Typography variant="h6" sx={{color: "white"}}>
                            Task Usage
                            <IconButton
                                aria-label="delete"
                                color="primary"
                                sx={{ml: 3}}
                                onClick={fetchUsage}
                            >
                                <Cached/>
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>
                <TaskProgress
                    purchase={purchaseReducer.purchase}
                    solutions={solutionReducer.solution}
                    refreshUsage={usage}
                />
            </BottomBar>
        </SideTabs>
    )
}

export default SideTabComp
