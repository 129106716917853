import React, {FC} from "react";

// todo disable the routes that do not serve any meaningful data, this will help with #16 SEO
// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Guards
import AuthGuard, {AuthGuardProps} from "./AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPasswordStep1 from "../pages/auth/reset-password/Step1";
import ResetPasswordStep2 from "../pages/auth/reset-password/Step2";
import Page404 from "../pages/auth/Page404";

// Components
import Cards from "../pages/components/Cards";

// Form components
// Icon components
// Page components
import InvoiceDetails from "../pages/pages/InvoiceDetails";
import InvoiceList from "../pages/pages/InvoiceList";
import Orders from "../pages/pages/Orders";
import Pricing from "../pages/pages/Pricing";
import Settings from "../pages/pages/Settings";
import AvailableApps from "../pages/pages/AvailableApps";
import Chat from "../pages/pages/Chat";

// Table components
// Dashboard components
import "../pages/dashboards/Default";
import "../pages/dashboards/Analytics";
import "../pages/dashboards/SaaS";

// Form components
import "../pages/forms/Pickers";
import "../pages/forms/Editors";
import "../pages/forms/Formik";

// Icon components
import "../pages/icons/FeatherIcons";
import Profile from "../pages/pages/Profile";

import "../pages/pages/Tasks";
import "../pages/pages/Calendar";

// Table components
import "../pages/tables/DataGrid";

// Chart components
import "../pages/charts/Chartjs";
import "../pages/charts/ApexCharts";

// Maps components
import "../pages/maps/GoogleMaps";
import Solutions from "../pages/pages/Solutions";

interface RouteType {
    path: string,
    component: FC,
    guard?: (props: AuthGuardProps) => React.ReactNode,
    authReq?: boolean
}

// All Routes
export const Paths: RouteType[] = [
    {
        path: "/",
        component: Solutions,
        guard: AuthGuard,

    },
    {
        path: "/solutions",
        component: Solutions,
        guard: AuthGuard
    },
    {
        path: "/solutions/:id",
        component: Profile,
        guard: AuthGuard,
        authReq: true
    },
    {
        path: "/dashboard",
        component: Solutions,
    },
    {
        path: "/explore",
        component: Solutions,
        guard: AuthGuard,
    },
    {
        path: "/apps",
        component: AvailableApps,
        guard: AuthGuard,

    },
    // {
    // component: Solutions,
    //   path: "/available_apps",
    //   component: AvailableApps,
    // },
    {
        path: "/apps/:id",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/preferences",
        component: Solutions,
        guard: AuthGuard,
    },
    {
        path: "/callback/:from",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solution-test",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/sign-in",
        component: SignIn,
        // guard: AuthGuard,
    },
    {
        path: "/sign-up",
        component: SignUp,
        // guard: AuthGuard,
    },
    {
        path: "/reset-password",
        component: ResetPasswordStep1,
        // guard: AuthGuard,
    },
    {
        path: "/reset",
        component: ResetPasswordStep2,
        // guard: AuthGuard,
    },
    {
        path: "/404",
        component: Page404,
    },
    {
        path: "/components/cards",
        component: Cards,
        guard: AuthGuard,
    },
    {
        path: "/settings",
        component: Settings,
        guard: AuthGuard,
        authReq: true
    },
    {
        path: "/pricing",
        component: Pricing,
        guard: AuthGuard,
    },
    {
        path: "/chat",
        component: Chat,
        guard: AuthGuard,
    },
    // {
    //   path: "/projects",
    //   component: Projects,
    //   guard: AuthGuard,
    // },
    {
        path: "/Orders",
        component: Orders,
        guard: AuthGuard,
    },
    {
        path: "/Invoices/list",
        component: InvoiceList,
        guard: AuthGuard,
    },
    {
        path: "/invoices/detail",
        component: InvoiceDetails,
        guard: AuthGuard,
    },
    // {
    //   path: "/tasks",
    //   component: Tasks,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/calendar",
    //   component: Profile,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/alerts",
    //   component: Alerts,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/accordion",
    //   component: Accordion,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/avatars",
    //   component: Avatars,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/badges",
    //   component: Badges,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/buttons",
    //   component: Buttons,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/cards",
    //   component: Cards,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/chips",
    //   component: Chips,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/dialogs",
    //   component: Dialogs,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/Lists",
    //   component: Lists,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/menus",
    //   component: Menus,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/pagination",
    //   component: Pagination,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/progress",
    //   component: Progress,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/snackbar",
    //   component: Snackbars,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Components/tooltips",
    //   component: Tooltips,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Charts",
    //   component: Blank,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/Charts/apexcharts",
    //   component: Blank,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "forms/selection-controls",
    //   component: SelectionControls,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/forms/selects",
    //   component: Selects,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/forms/text-fields",
    //   component: TextFields,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/forms/editors",
    //   component: Editors,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/forms/formik",
    //   component: FormikPage,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/tables/simple-table",
    //   component: SimpleTable,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/tables/advanced-table",
    //   component: AdvancedTable,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/tables/data-grid",
    //   component: DataGridPage,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/icons/material-icons",
    //   component: MaterialIcons,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/icons/feather-icons",
    //   component: FeatherIcons,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/maps/google-maps",
    //   component: GoogleMaps,
    //   guard: AuthGuard,
    // },
    // {
    //   path: "/maps/vector-maps",
    //   component: VectorMaps,
    //   guard: AuthGuard,
    // }
];

// Specific Routes

export const AuthRoutes: RouteType[] = [
    {
        path: "/settings",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/sign-in",
        component: SignIn,
        guard: AuthGuard,
    },
    {
        path: "/sign-up",
        component: SignUp,
        guard: AuthGuard,
    },
    {
        path: "/reset-password",
        component: ResetPasswordStep1,
        guard: AuthGuard,
    },
    {
        path: "/reset",
        component: ResetPasswordStep2,
        guard: AuthGuard,
    }
];

export const CommonRoutes: RouteType[] = [
    {
        path: "/solutions",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/:id",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/apps",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/apps",
        component: Profile,
        guard: AuthGuard,
    },
];

export const UserRoutes: RouteType[] = [
    {
        path: "/solutions",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/:id",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/apps",
        component: Profile,
        guard: AuthGuard,
    },
    {
        path: "/solutions/apps",
        component: Profile,
        guard: AuthGuard,
    },
];
