import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import React, {FC, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useAppSelector from "../../../hooks/useAppSelector";
import {AppModel} from "../../../models/app.model";
import URL from "../../../services/Apis";
import {get} from "../../../services/AxiosRequestMethods";
import AppsComponent from "../AppsComponent";
import SolutionModel from "../../../models/solution.model";

export const getDate = (date: Date | string | any) => {
    let newDate = new Date(date)
    return `${newDate.getFullYear()}.${newDate.getMonth() + 1}.${
        newDate.getDate() + 1
    }`
}

interface Props {
    purchaseId?: number
}

const ProfileApps: FC<Props> = (props: Props) => {
    const [apps, setApps] = useState<AppModel[]>([]);
    const user = useAppSelector((state) => state.userReducer.user);
    const location = useLocation();
    const getSlug = () => location?.pathname?.split("/")[2].toLowerCase();

    useEffect(() => {
        getAppsData()
    }, [])

    const getAppsData = () => {
        get<SolutionModel>(
            `${URL.Solutions.getSolutions}/${getSlug()}`,
            user?.token
        ).then(solutions => {
            setApps(solutions?.apps)
        }).catch(e => {
            console.error(`Error occurred in fetching solutions.`, e)
        })
    }

    return (
        <React.Fragment>
            <Typography sx={{fontSize: "18px"}}>
                This solution requires the following apps:
            </Typography>
            <Box marginTop={5}>
                {apps.length > 0 && <AppsComponent appsData={apps} purchaseId={props.purchaseId} status={true}/>}
            </Box>
        </React.Fragment>
    );
};

export default ProfileApps;
