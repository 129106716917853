import jsIcon from "../assets/icons/jsIcon.svg";
import pyIcon from "../assets/icons/pyIcons.svg";
import zapierIcon from "../assets/icons/zapierIcon.svg";
import curlIcon from "../assets/icons/curlicon.svg";
import scriptIcon from "../assets/icons/scriptIcon.svg";
import airtableIcon from "../assets/icons/airtableIcon.svg";
import nodeIcon from "../assets/icons/node.svg";

export interface IProps {
  token: string | undefined;
  script: any;
  active: boolean;
  language: string;
  Setlanguage: (e: any) => void;
  scriptsData?: any;
}

export interface Ilang {
  lang: string;
  icon: string;
  title: string;
}

export const StaticLanguages: Ilang[] = [
  {
    lang: "apps_script",
    icon: scriptIcon,
    title: "Google Apps Script",
  },
  {
    lang: "zapier_js",
    icon: zapierIcon,
    title: "Zapier Js",
  },
  {
    lang: "airtable_script",
    icon: airtableIcon,
    title: "Airtable Script"
  },
  {
    lang: "zapier_py",
    icon: zapierIcon,
    title: "Zapier Py",
  },
  {
    lang: "airtable",
    icon: airtableIcon,
    title: "Airtable",
  },
  {
    lang: "airtable_script_block",
    icon: airtableIcon,
    title: "Airtable Script",
  },
  {
    lang: "Curl",
    icon: curlIcon,
    title: "cURL",
  },
  {
    lang: "javascript",
    icon: jsIcon,
    title: "Javascript",
  },
  {
    lang: "node",
    icon: nodeIcon,
    title: "Node.js",
  },
  {
    lang: "python",
    icon: pyIcon,
    title: "Python",
  },
];

export interface IAttribute {
  Curl: string;
  airtable: string;
  apps_script: string;
  createdAt: string;
  javascript: string;
  node: string;
  position: string;
  publishedAt: string;
  python: string;
  response: string;
  test_payload: string;
  title: string;
  unique_title: string;
  updatedAt: string;
  zapier: string;
}
export interface IScript {
  id: number;
  attributes: IAttribute;
}
