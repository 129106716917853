import React from "react";
import {useSearchParams} from "react-router-dom";
import {isValidToken, setSession} from "../../../utils/jwt";
import {NewPassword, ResetPasswordNoToken} from "../../../components/auth/ResetPassword";

function ResetPasswordStep2() {
    let [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    if (!token || !isValidToken(token))
        return <ResetPasswordNoToken/>

    setSession(token)

    return <NewPassword/>
}

export default ResetPasswordStep2
