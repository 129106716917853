import Axios, {AxiosRequestConfig} from "axios";
import {store} from "../redux/store";

export const get = async <T>(endPoint: string, header?: string): Promise<T> => {

    const config: AxiosRequestConfig<T> = {}

    if (header)
        config.headers = {Authorization: header}

    return new Promise<T>((resolve, reject) => {
        Axios.get<T>(endPoint, config)
            .then((response) => {
                if (response) {
                    // store.dispatch({ type: "LOGOUT" });
                    resolve(response.data)
                }
            })
            .catch((error) => {
                console.error(error);
                if (
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    store.dispatch({type: "LOGOUT"});
                }
                reject(error)
            })
    })
}

export const post = async <T>(endPoint: string, data: [] | {}, config = {}) =>
    new Promise<T>((resolve, reject) => {
        Axios.post(endPoint, data, config)
            .then((response: any) => {
                if (response)
                    resolve(response.data)
            })
            .catch((error) => {
                console.error("response error", error)
                if (
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    store.dispatch({type: "LOGOUT"})
                }
                reject(error)
            })
    })

export const put = async (endPoint: string, data?: [] | {}, headers?: {}) =>
    new Promise((resolve, reject) => {
        Axios.put(endPoint, data, headers)
            .then((response) => {
                if (response) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.error(JSON.stringify(error.response))
                if (
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    store.dispatch({type: "LOGOUT"});
                }
                reject(error);
            });
    });

export const del = async (endpoint: string, header: string | undefined, data?: any) => {
    new Promise((resolve, reject) => {
        Axios.delete(endpoint, {
            data,
            headers: {
                Authorization: `${header}`,
            },
        })
            .then((res) => {
                if (res) {
                    resolve(res.data);
                }
            })
            .catch((error) => {
                if (
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    store.dispatch({type: "LOGOUT"});
                }
                reject(error);
                throw error;
            });
    });
};
