import {CacheProvider} from "@emotion/react";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PropTypes from "prop-types";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Provider} from "react-redux";
import "./i18n";
import createTheme from "./theme";

import {Toaster} from 'react-hot-toast';
import {PersistGate} from "redux-persist/integration/react";
import useTheme from "./hooks/useTheme";
import {persister, store} from "./redux/store";
import Routes from "./routes/Routes";
import createEmotionCache from "./utils/createEmotionCache";

const stripePromise = loadStripe('pk_test_pcFYJJ0SQoVRGwqIgXnVXlW3');

const clientSideEmotionCache = createEmotionCache();

function App({emotionCache = clientSideEmotionCache}) {
    const {theme} = useTheme()

    // Use this in production
    // useEffect(() => {
    //     // console.log = () => {};
    //     console.error = () => {
    //     };
    //     console.warn = () => {
    //     };
    // }, [])

    // console.log(`Loading Matrix v` + process.env.REACT_APP_MATRIX_VERSION)

    return (
        <CacheProvider value={emotionCache}>
            <HelmetProvider>
                <Helmet
                    titleTemplate="%s | Infinity"
                    defaultTitle="Infinity Web Marketplace"
                />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persister}>
                        <MuiThemeProvider theme={createTheme(theme)}>
                            <Elements stripe={stripePromise}>
                                <Routes/>
                                <Toaster/>
                            </Elements>
                            {/* <AuthProvider>{Routes}</AuthProvider> */}
                        </MuiThemeProvider>
                    </PersistGate>
                </Provider>
            </HelmetProvider>
        </CacheProvider>
    )
}

App.propTypes = {
    emotionCache: PropTypes.any
}

export default App
