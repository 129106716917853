import * as React from "react"
import styled from "@emotion/styled"
import {version} from "../../package.json"

import {
    Grid,
    List,
    ListItemButton as MuiListItemButton,
    ListItemButtonProps as MuiListItemButtonProps,
    ListItemText as MuiListItemText,
} from "@mui/material";

interface ListItemButtonPropsInterface extends MuiListItemButtonProps {
    component?: string
    target?: '_blank' | '_top'
    href?: string
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)} ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton) <ListItemButtonPropsInterface>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  display: inline-block;

  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
    return (
        <Wrapper>
            <Grid container spacing={0}>
                <Grid container item sx={{display: {xs: "none", md: "block"}}} xs={12} md={6}>
                    <List>
                        {/*<ListItemButton component="a" href="#">*/}
                        {/*  <ListItemText primary="Support" />*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton component="a" href="#">*/}
                        {/*  <ListItemText primary="Help Center" />*/}
                        {/*</ListItemButton>*/}
                        <ListItemButton component="a" target="_blank" href="//www.infinityweb.app/legal/privacy-policy">
                            <ListItemText primary="Privacy"/>
                        </ListItemButton>
                        <ListItemButton component="a" target="_blank"
                                        href="//www.infinityweb.app/legal/terms-of-service">
                            <ListItemText primary="Terms of Service"/>
                        </ListItemButton>
                        <ListItemText primary={`Matrix v${version}`}/>
                    </List>
                </Grid>
                <Grid container item xs={12} md={6} justifyContent="flex-end">
                    <List>
                        <ListItemText
                            primary={`© ${new Date().getFullYear()} Infinity Web, LLC`}
                        />
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    )
}

export default Footer
