import React from "react";
import styled from "@emotion/styled";
import * as Yup from "yup";
import {Formik} from "formik";

import {Alert as MuiAlert, Button, Paper, TextField as MuiTextField, Typography,} from "@mui/material";
import {spacing} from "@mui/system";
import URL from "../../services/Apis";
import {post} from "../../services/AxiosRequestMethods";
import {Helmet} from "react-helmet-async";
import * as fetch from "node-fetch"
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import Logo from "../../assets/images/Logo-Icon-Regular.svg";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface Values {
    email?: string,
    password?: string
}

function ResetPasswordComponent() {
    const handleSubmit = async (values: Values) => {
        const dataToSend = {
            headers: {
                "Content-Type": "application/json",
            },
            email: values.email,
        }

        try {
            await post(URL.Users.forgotPassword, dataToSend)
            toast.success(`Please follow the link sent to your email address.`)
        } catch (e) {
            console.error(`Error in reset password`, e)
            toast.error(`There is some error. Please try again later.`)
        }
    }

    return (
        <Formik
            initialValues={{
                email: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
            })}
            onSubmit={async (values) => {
                await handleSubmit(values);
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}
                    <TextField
                        type="email"
                        name="email"
                        label="Email Address"
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        Reset password
                    </Button>
                </form>
            )}
        </Formik>
    );
}

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`
const Brand = styled.img`
  margin-top: 1rem;
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

function ResetPasswordNoToken() {
    return <React.Fragment>
        <Brand src={Logo}/>
        <Wrapper>
            <Helmet title="Reset Password"/>

            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Error
            </Typography>
            <Typography component="h2" variant="body1" align="center">
                No valid token found. Please try to reset your password again.
            </Typography>

        </Wrapper>
    </React.Fragment>
}

function NewPassword() {
    const navigate = useNavigate()
    const token = localStorage.getItem("accessToken")
    console.log(`Token: ${token}`)
    const handleSubmit = async (values: Values) => {
        if (!token) return

        try {
            const r = await fetch(URL.Users.resetPassword, {
                method: 'put',
                body: JSON.stringify({
                    password: values.password
                }),
                headers: {
                    "Authorization": token,
                    "Content-Type": "application/json"
                }
            })

            if (r.ok) {
                toast.success('Password is updated, please sign in with your new password.')
                navigate('/')
                // return r.json()
            } else
                toast.error('Password could not be updated.')
        } catch (e) {
            toast.error('Password could not be updated.')
        }
    }

    return <React.Fragment>
        <Brand src={Logo}/>
        <Wrapper>
            <Helmet title="Reset Password"/>

            <Typography component="h1" variant="h4" align="center" gutterBottom>
                Reset Password
            </Typography>
            <Typography component="h2" variant="body1" align="center">
                Enter new password
            </Typography>

            <Formik
                initialValues={{
                    password: "",
                    submit: false,
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .min(6)
                        .max(255)
                        .required("Password is required"),
                })}
                onSubmit={async (values) => {
                    await handleSubmit(values);
                }}
            >
                {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        {errors.submit && (
                            <Alert mt={2} mb={1} severity="warning">
                                {errors.submit}
                            </Alert>
                        )}
                        <TextField
                            type="password"
                            name="password"
                            label="New Password"
                            value={values.password}
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            my={3}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                        >
                            Save
                        </Button>
                    </form>
                )}
            </Formik>

        </Wrapper>
    </React.Fragment>
}

export {ResetPasswordComponent, ResetPasswordNoToken, NewPassword}
