import React from "react";
import { Global, css } from "@emotion/react";

const GlobalStyle = (props: any) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #__next {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }

      /* Script Code Block */
      .csNcpH {
        border-radius: 0 !important;
      }

      .custom-video {
        display: flex;
      }

      .vimeo {
        padding: 56.25% 0 0 0;
        position: relative;
      }

      .vimeo iframe,
      .vimeo object,
      .vimeo embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: #e9e9e9;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #3c4257;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `}
  />
);

export default GlobalStyle;
