import {SolutionReducerType} from "./types";

const initialState: SolutionReducerType = {
    solution: {
        id: -1,
        solutionCode: "",
        slug: "",
        cfw: "",
        planId: -1,
        creator: -1,
        active: false,
        type: 1,
        name: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        apps: []
    }
}

const solutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SOLUTIONS":
            return {...state, solution: action.payload}
        default:
            return state
    }
}

export default solutionReducer
