import {Action, UserReducer} from "./types"
import UserModel from "../../models/user.model"

const initialState: UserReducer = {
    user: {id: -1, token: "", email: ""},
    isLogin: false,
    goBack: false
}

const userReducer = (state: UserReducer = initialState, action: Action): UserReducer => {
    switch (action.type) {
        case "LOGIN":
            return {...state, user: action.payload as UserModel, isLogin: true}

        case "REGISTER":
            return {...state, user: action.payload as UserModel, isLogin: true}

        case "LOGOUT":
            return {...state, user: null, isLogin: false, goBack: false}

        case "UPDATE":
            return {...state, user: action.payload as UserModel}

        case "PREVIOUS_ROUTE_CHECK":
            return {...state, goBack: !!action.payload}

        default:
            return state
    }
};

export default userReducer
