import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Collapse } from "@mui/material";
import IconArrowRight from "@mui/icons-material/ArrowRightAlt";

type CollapseOptionType = {
  title?: string;
  description?: string;
  button?: string;
  children?: React.ReactNode;
  active?: boolean;
  onChange?: (e: boolean) => void;
};

const CollapseOption = (props: CollapseOptionType) => {
  const { title, description, button, active, children, onChange } = props;
  const [isActive, setIsActive] = useState<boolean>(active ? active : false);

  useEffect(() => {
    setIsActive(active ? active : false);
  }, [active]);

  useEffect(() => {
    if (onChange) onChange(isActive);
  }, [isActive, onChange]);

  return (
    <Box
      sx={{
        width: "100%",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "divider",
        paddingBottom: "2rem",
        marginTop: "1rem",
      }}
    >
      {title && (
        <Typography variant="h4" component="h4">
          {title}
        </Typography>
      )}
      {description && (
        <Box sx={{ maxWidth: "400px", mt: "0.5rem" }}>
          <Typography variant="caption" component="span">
            {description}
          </Typography>
        </Box>
      )}
      {button && (
        <Button
          disableRipple
          onClick={() => setIsActive(true)}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            opacity: isActive ? 0 : 1,
            pointerEvents: isActive ? "none" : "all",
            paddingTop: isActive ? 0 : "0.5rem",
            paddingBottom: isActive ? 0 : "0.5rem",
            transition: "all 300ms ease",

            svg: {
              transition: "transform 300ms ease",
            },

            "&:hover": {
              backgroundColor: "transparent",
              svg: {
                transform: "translateX(10px)",
              },
            },
          }}
          endIcon={<IconArrowRight style={{ fontSize: "0.65rem" }} />}
        >
          {button}
        </Button>
      )}
      <Collapse in={isActive}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapseOption;
