import {combineReducers} from "redux"

import userReducer from "./userReducer"
import solutionReducer from "./solutionReducer"
import purchaseReducer from "./PurchaseReducer";

export const rootReducer = combineReducers({
  userReducer,
  solutionReducer,
  purchaseReducer
})
