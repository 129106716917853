import styled from "@emotion/styled";
import {Box, Button, Card as MuiCard, CardContent, FormControl, Grid, InputLabel, Typography} from "@mui/material";
import {spacing} from "@mui/system";
import {ChangeEvent, useState} from "react";
import toast from "react-hot-toast";
import useAppSelector from "../../hooks/useAppSelector";
import URL from "../../services/Apis";
import {post} from "../../services/AxiosRequestMethods";
import CollapseOption from "../CollapseOption";
import PasswordInput from "../PasswordInput";
import {useDispatch} from "react-redux";
import UserModel from "../../models/user.model";
import Axios, {AxiosResponse} from "axios";
import {store} from "../../redux/store";

const Card = styled(MuiCard)(spacing);
let timeoutClear: any;

const AccountSettings = () => {
    const dispatch = useDispatch()
    const user = useAppSelector((state) => state.userReducer.user);
    const [isActiveChangePassword, setIsActiveChangePassword] = useState(false);
    const [, setIsActiveSSOChangePassword] = useState(false);
    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({
        confirmPassword: {
            error: false,
            message: "",
        },
        newPassword: {
            error: false,
            message: "",
        },
    });

    const handleUpdateForm = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event?.target;
        setPasswordData({...passwordData, [name]: value});
    };

    const onResetUpdatePassword = () => {
        setPasswordData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
        setIsActiveChangePassword(false);
        setIsActiveSSOChangePassword(false);
    };

    const handleUpdatePassword = async () => {
        const {newPassword, oldPassword, confirmPassword} = passwordData;
        if (!newPassword || !oldPassword)
            return toast.error("Provide credentials.");

        if (newPassword !== confirmPassword)
            return toast.error("New and Confirm Password must match");

        const currentToken = user?.token

        if (!currentToken) {
            store.dispatch({type: "LOGOUT"})
            return
        }

        const config = {
            url: URL.Users.changePassword,
            method: "POST",
            headers: {
                Authorization: currentToken,
                "Content-Type": "application/json;charset=UTF-8",
            },
            data: {newPassword, oldPassword},
        }

        try {
            const response = await Axios.post<{ oldPassword: string, newPassword: string }, AxiosResponse<UserModel>, any>(config.url, config.data, {
                headers: config.headers
            })

            // Setting the new token in the local-storage
            dispatch({
                type: "LOGIN",
                payload: response.data
            })

            localStorage.setItem("isLogin", "true")
            return toast.success("Password changed successfully.")
        } catch (e) {
            return toast.error("Password could not be changed.")
        }
    };

    // const handleLogoutAllSessions = async () => {
    //     await del(URL.Users.logoutAll, user?.token)
    //         .then((res) => {
    //             toast.success("Logged out of all sessions.");
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // };
    //
    // const handleDeactivateAccount = async () => {
    //     const config = {
    //         headers: {
    //             Authorization: user?.token,
    //             "Content-Type": "application/json;charset=UTF-8",
    //         },
    //     };
    //     await put(URL.Users.deactivate, {}, {headers: config.headers}).then(
    //         (data) => {
    //             dispatch({
    //                 type: "LOGOUT",
    //             });
    //             navigate("/sign-in");
    //             toast.success("deactivated");
    //         }
    //     );
    // };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom mb={2}>
                    Account
                </Typography>
                <CollapseOption
                    title={`Password`}
                    description={`New tasks assigned to you, @mentions, and completion notifications for tasks you're a collaborator on`}
                    button={`Change the password for your account`}
                    active={isActiveChangePassword}
                    onChange={setIsActiveChangePassword}
                >
                    <Box>
                        <Typography sx={{mb: 2}} variant="h6" component="h6">
                            Change the password for your account
                        </Typography>
                        <Box sx={{maxWidth: "800px"}}>
                            <form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            <InputLabel shrink htmlFor="current-password">
                                                Current password
                                            </InputLabel>
                                            <br/>
                                            <PasswordInput
                                                name="oldPassword"
                                                onChange={handleUpdateForm}
                                                required
                                                placeholder="Password"
                                                id="current-password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            <InputLabel shrink htmlFor="new-password">
                                                New password
                                            </InputLabel>
                                            <br/>
                                            <PasswordInput
                                                error={errors?.newPassword?.error}
                                                errorMessage={errors?.newPassword?.message}
                                                name="newPassword"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateForm(e);
                                                    const {value} = e?.target;
                                                    clearTimeout(timeoutClear);
                                                    timeoutClear = setTimeout(() => {
                                                        if (value.length < 6) {
                                                            setErrors({
                                                                ...errors,
                                                                newPassword: {
                                                                    error: true,
                                                                    message: "Password is less than 6 characters",
                                                                },
                                                            });
                                                        } else {
                                                            setErrors({
                                                                ...errors,
                                                                newPassword: {
                                                                    error: false,
                                                                    message: "",
                                                                },
                                                            });
                                                        }
                                                    }, 500);
                                                }}
                                                required
                                                placeholder="New Password"
                                                id="new-password"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            <InputLabel shrink htmlFor="confirm-password">
                                                Confirm new password
                                            </InputLabel>
                                            <br/>
                                            <PasswordInput
                                                error={errors?.confirmPassword?.error}
                                                name="confirmPassword"
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateForm(e);

                                                    const {value} = e?.target;

                                                    clearTimeout(timeoutClear);
                                                    timeoutClear = setTimeout(() => {
                                                        if (value !== passwordData?.newPassword) {
                                                            setErrors({
                                                                ...errors,
                                                                confirmPassword: {
                                                                    error: true,
                                                                    message:
                                                                        "New and Confirm Password must match",
                                                                },
                                                            });
                                                        } else {
                                                            setErrors({
                                                                ...errors,
                                                                confirmPassword: {
                                                                    error: false,
                                                                    message: "",
                                                                },
                                                            });
                                                        }
                                                    }, 500);
                                                }}
                                                required
                                                placeholder="Confirm Password"
                                                id="confirm-password"
                                                errorMessage={errors?.confirmPassword?.message}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Button
                                                onClick={onResetUpdatePassword}
                                                variant="outlined"
                                                color="info"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                sx={{ml: "0.5rem"}}
                                                variant="contained"
                                                onClick={(e) => {
                                                    e?.preventDefault();
                                                    handleUpdatePassword().then(r => {
                                                    })
                                                }}
                                            >
                                                Change password
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </CollapseOption>
            </CardContent>
        </Card>
    );
};

export default AccountSettings;
