// import { FC } from 'preact/compat'
import React, {FC} from "react";
import {BrowserRouter as Router, Route, Routes as Switch,} from "react-router-dom";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import {Paths} from "./Paths";
import HomeHandler from "./HomeHandler";
import UnAuthGuard from "./UnAuthGuard";
import {AuthGuardProps} from "./AuthGuard";


interface RouteType {
    path: string,
    component: FC,
    guard?: (props: AuthGuardProps) => React.ReactNode,
    authReq?: boolean
}

const childRoutes = (routes: RouteType[]) =>
    routes.map(({component: Component, guard, path, authReq}) => {
        // const Guard: any = guard || React.Fragment;
        const Guard: any = guard || UnAuthGuard;

        return (
            <Route
                key={path}
                path={path}
                element={
                    !guard ? (
                        <AuthLayout>
                            <Guard authReq={authReq}>
                                <Component/>
                            </Guard>
                        </AuthLayout>
                    ) : (
                        // <AuthLayout>
                        <DashboardLayout>
                            <Guard authReq={authReq}>
                                <Component/>
                            </Guard>
                        </DashboardLayout>
                    )
                    // </AuthLayout>
                }
            />
        );
    });
const Routes: FC = () => {

    return (
        <Router>
            <Switch>
                {childRoutes(Paths)}
                <Route path="/" element={<HomeHandler/>}/>
                <Route path="*" element={<Page404/>}/>
            </Switch>
        </Router>
    );
};

export default Routes;
