import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import useAppSelector from '../hooks/useAppSelector';

// type Props = {
//     children: React.ReactNode;
//     authReq:boolean
// };

function UnAuthGuard({children}) {
    const loc = useLocation()
    const navigate = useNavigate()
    const user = useAppSelector(state => state.userReducer)

    useEffect(() => {
        if (user.isLogin && loc.pathname === '/sign-in') {
            navigate('/explore');
        }
    }, [user])
    
  return children
}

export default UnAuthGuard
