import React, {useEffect} from "react"
import {useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import {RootState} from "../redux/store"

export type AuthGuardProps = {
    children: React.ReactNode
    authReq: boolean
}

const AuthGuard = ({children, authReq}: AuthGuardProps) => {
    const user = useSelector((state: RootState) => state.userReducer)
    const navigate = useNavigate()

    useEffect(() => {
        if (!user.isLogin && authReq)
            navigate('/sign-in')
    }, [window.location.href])

    return children
}

export default AuthGuard
