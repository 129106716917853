import styled from "@emotion/styled"
import {Box, Card, CardActionArea, CardActions, CardContent, CardMedia as MuiCardMedia, Typography} from "@mui/material"
import {FC} from "react"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import SolutionModel from "../../../models/solution.model"

type Props = {
    data: SolutionModel
}

const CardWrapper = styled(Card)`
  cursor: pointer;
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 90%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bolder;
  padding: 2rem;

  ${props => props.theme.breakpoints.up('xl')} {
    width: 95%;
  }

  ${props => props.theme.breakpoints.down('lg')} {
    width: 75%;
  }
`;

const CardMedia = styled(MuiCardMedia)`
  height: 48px;
  width: 48px;
  margin-right: 1rem;
`

const CardActionsWrapper = styled(CardActions)`
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
`

const CardMediaWrapper = styled(Box)`
  display: flex;
  padding-left: 1rem;
  height: 2rem;
`

const MediaCard: FC<Props> = ({data}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDetailsClick = (data: SolutionModel) => {
        dispatch({type: "GET_SOLUTIONS", payload: data})
        navigate(`/solutions/${data?.slug}`)
    }

    return <CardWrapper onClick={() => handleDetailsClick(data)}>
        <CardActionArea>
            <CardMediaWrapper>
                {data.apps.map((app, index) => (
                    <CardMedia
                        key={index}
                        image={app.iconUrl && app.iconUrl}
                        title={app.name}
                    />
                ))}
            </CardMediaWrapper>
        </CardActionArea>
        <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
                {data?.name}
            </Typography>
        </CardContent>
        <CardActionsWrapper>
            <Typography sx={{fontWeight: "bolder"}}>Go To Details</Typography>
        </CardActionsWrapper>
    </CardWrapper>
}

export default MediaCard
