import {PictureAsPdf} from "@mui/icons-material";
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import useAppSelector from "../../hooks/useAppSelector";
import URL from "../../services/Apis";
import {get} from "../../services/AxiosRequestMethods";
import PurchaseModel from "../../models/purchase.model";
import Loader from "../Loader";

interface Column {
  id:
    | "purchaseId"
    | "solutionId"
    | "requests"
    | "billedFrom"
    | "billedTo"
    | "status"
    | "charges"
    | "invoicedetails";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface IUsageData {
  purchase_id: number;
  solution_id: number;
  usages: IUsages[];
}

interface IUsages {
  amount: number | any;
  billed: IBilled;
  billingPeriodId: number;
  createdAt: string;
  currency: string;
  dueDate: null;
  id: number;
  status: string;
  stripeInvoiceId: string;
  stripeInvoiceUrl: string;
  updatedAt: string;
  userId: number;
}

interface IBilled {
  createdAt: string;
  from: string;
  id: number;
  name: string;
  to: string;
  updatedAt: string;
  userId: number;
}

interface IBillingPeriod extends IUsageData {
  solutionId: number
  billed: string
  billingPeriodId: number
  createdAt: string
  from: string
  id: number
  name: string
  to: string
  updatedAt: string
  userId: number
}

const columns: readonly Column[] = [
  {
    id: "billedFrom",
    label: "Billed From",
    minWidth: 170,
    format: (value: number) => value.toString(),
  },
  {
    id: "billedTo",
    label: "Billed To",
    minWidth: 170,
    format: (value: number) => value.toString(),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    format: (value: number) => value.toString(),
  },
  {
    id: "charges",
    label: "Charges",
    minWidth: 170,
    format: (value: number) => value.toString(),
  },
  {
    id: "invoicedetails",
    label: "Invoice details",
    minWidth: 170,
    format: (value: number) => value.toString(),
  },
];

function BillingHistory() {
  const [newData, setNewData] = useState<IUsageData[]>([]);
  const [opened] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const user = useAppSelector((state) => state.userReducer.user);

  useEffect(() => {
    getPurchases();
  }, []);

  const getBillingPeriods = async (
      data: IUsages[],
      index: number,
      arr: IBillingPeriod[]
  ): Promise<IUsages[]> => {
    if (index === data.length) return data;
    let usage = data[index];
    let tempArray = [...arr];
    const billingData = await get<any>(
        `${URL.billing.getBillingById}${usage.billingPeriodId}`,
        user?.token
    )
    let temp = [...data];
    temp[index].billed = billingData;
    tempArray.push(billingData);

    return getBillingPeriods(temp, index + 1, tempArray)
  }

  const getUsage = async (
      data: PurchaseModel[],
      index: number,
      arr: IUsageData[]
  ): Promise<IUsageData[]> => {
    if (index === data.length) return arr
    let purchase = data[index]
    if (purchase?.id) {
      await get<IUsages[]>(`${URL.Invoice.getBillingInvoice}`, user?.token)
          .then(usages => {
            if (usages.length)
              return getBillingPeriods(usages, 0, [])
            return usages
          }).then(usages => {
            const temp: IUsageData = {
              purchase_id: purchase.id!,
              solution_id: purchase.solutionId,
              usages
            }

            if (temp.purchase_id) {
              let usage_data = [...arr]
              usage_data.push(temp)
              arr = usage_data
              setNewData(usage_data)
            }
          })
          .catch(function (error: Error) {
            console.error(error)
          })
    }
    return getUsage(data, index + 1, arr)
  }

  function getPurchases() {
    setLoading(true);
    get<PurchaseModel[]>(URL.Purchases.getPurchases, user?.token)
        .then(async function (response: PurchaseModel[]) {
          const finalData = await getUsage(response, 0, [])
          setNewData(finalData)
          setLoading(false)
        })
        .catch(function (error: Error) {
          console.error(error);
          setLoading(false);
      });
  }

  return (
    <CardContent>
      <h1>Billing history</h1>
      {!loading ? (
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newData.length > 0 &&
                newData.map((data) => {
                  return data.usages?.map((usage: IUsages, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>
                            {dayjs(usage?.billed?.from).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {dayjs(usage?.billed?.to).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>{usage?.status}</TableCell>
                          <TableCell>${parseFloat(usage?.amount)}</TableCell>
                          <TableCell>
                            <a
                              href={usage?.stripeInvoiceUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <PictureAsPdf />
                            </a>
                          </TableCell>
                        </TableRow>
                        {index === opened && (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <Typography>
                                  Details from invoices will be shown here
                                </Typography>
                              </TableCell>
                            </TableRow>
                        )}
                      </React.Fragment>
                    );
                  });
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loader />
      )}
    </CardContent>
  );
}

export default BillingHistory;
