import { useState, ComponentProps } from "react";
import IconEye from "@mui/icons-material/VisibilityOutlined";
import { InputBase, Box } from "@mui/material";

type InputBaseType = ComponentProps<typeof InputBase>;

interface IProps extends InputBaseType {
  errorMessage?: string;
}

const PasswordInput = (props: IProps) => {
  const [active, setActive] = useState(false);
  //   const classes = useStyles();

  return (
    <>
      <InputBase
        sx={{
          borderRadius: "5px",
          padding: "5px 10px",
          border: `${props?.error ? "1px solid red" : "1px solid #808080"}`,
        }}
        {...props}
        id="outlined-error"
        type={active ? "text" : "password"}
        endAdornment={
          <Box
            onClick={() => setActive(!active)}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              paddingRight: "1rem",
              color: active ? "primary.main" : "grey.800",
              transition: "color 300ms ease",
            }}
          >
            <IconEye />
          </Box>
        }
      />
      {props?.errorMessage && props?.error && (
        <fieldset style={{ color: "red", fontSize: "14px", marginTop: "4px" }}>
          <legend>{props?.errorMessage}</legend>
        </fieldset>
      )}
    </>
  );
};

export default PasswordInput;
