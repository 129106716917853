import React from "react";
import styled from "@emotion/styled";
import {Helmet} from "react-helmet-async";

import {Paper, Typography} from "@mui/material";
import {ResetPasswordComponent} from "../../../components/auth/ResetPassword";
import Logo from "../../../assets/images/Logo-Icon-Regular.svg";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Brand = styled.img`
  margin-top: 1rem;
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

function ResetPassword() {
    return (
        <React.Fragment>
            <Brand src={Logo}/>
            <Wrapper>
                <Helmet title="Reset Password"/>

                <Typography component="h1" variant="h4" align="center" gutterBottom>
                    Reset Password
                </Typography>
                <Typography component="h2" variant="body1" align="center">
                    Enter your email to reset your password
                </Typography>

                <ResetPasswordComponent/>
            </Wrapper>
        </React.Fragment>
    );
}

export default ResetPassword;
