import jwtDecode from "jwt-decode";
import {sign, verify} from "jsonwebtoken";
import axios from "./axios";

const isValidToken = (accessToken: string) => {
    if (!accessToken)
        return false

    try {
        const decoded = jwtDecode<{ exp: number }>(accessToken)
        const currentTime = Date.now() / 1000

        return decoded.exp > currentTime
    } catch (e) {
        console.error(`Error occurred in decoding a token.`, e)
        return false
    }
}

const setSession = (accessToken: string | null | undefined) => {
    if (accessToken) {
        localStorage.setItem("accessToken", accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem("accessToken");
        delete axios.defaults.headers.common.Authorization
    }
}

export {verify, sign, isValidToken, setSession}
