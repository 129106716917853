import styled from "@emotion/styled"
import {Box, Divider as MuiDivider, FormControlLabel, Grid, Switch, Typography} from "@mui/material"
import {useTheme} from "@mui/material/styles"
import {SwitchProps} from "@mui/material/Switch"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import {spacing} from "@mui/system"
import React, {FC, useEffect, useState} from "react"
import {Helmet} from "react-helmet-async"
import {useDispatch} from "react-redux"
import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import SwipeableViews from "react-swipeable-views"
import error_neutral from "../../assets/icons/error-cross-neutral.svg"
import States from "../../assets/icons/States.svg"
import useAppSelector from "../../hooks/useAppSelector"
import {AppModel} from "../../models/app.model"
import URL from "../../services/Apis"
import {get, post, put} from "../../services/AxiosRequestMethods"
import Overview from "../components/solutions/Overview"
import ProfileApps from "../components/solutions/ProfileApps"
import SolutionScripts from "../components/solutions/SolutionScripts"
import SolutionModel from "../../models/solution.model";
import PurchaseModel from "../../models/purchase.model";
import {PurchaseReducerType, SolutionReducerType} from "../../redux/reducers/types";
import toast from "react-hot-toast";
import TabModel from '../../models/StrapiModels/tab.model'

const Divider = styled(MuiDivider)(spacing)


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginRight: 6,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}))

// --------------------- Interfaces -----------------------
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    dir?: string
    value: number
}


interface StrapiResponse {
    data: TabModel[],
    meta: {
        pagination: {
            page: number,
            pageSize: number,
            pageCount: number,
            total: number
        }
    }
}

// --------------------- Functions -----------------------

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}
const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    }
}
// --------------------- CSS -----------------------

const CustomTabs = styled(Tabs)`
  background-color: #233044;
  border-radius: 8px;
  padding: 10px 2px 10px 2px;
`

const SingleTab = styled(Tab)`
  /* background-color: #efecfd; */
  margin: 0 10px;
  border-radius: 5px;
  justify-content: space-between;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-align: center;
  color: white;
  min-height: 50px;
  min-width: fit-content;

  &.Mui-selected {
    background-color: ${(props) => props.theme.palette.primary.main} !important;
  }
`

const AppImage = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 15px;
`

const AppStatusIcon = styled.img`
  position: absolute;
  top: 41px;
  left: 50px;
  height: 1.8rem;
`
const Profile: FC = () => {
    // ------- States & Other Hooks -------
    const [value, setValue] = useState(0)
    const [tabContent, setTabContent] = useState<TabModel[]>([])
    const [tab, setTabs] = useState<string>("overview")
    const [statusIcons] = useState<{ [key: string]: string }>({})
    const solutionReducer = useAppSelector<SolutionReducerType>((state) => state.solutionReducer)
    const [currentSolution, setCurrentSolution] = useState(solutionReducer.solution)
    const userReducer = useAppSelector((state) => state.userReducer)
    const user = userReducer.user
    const dispatch = useDispatch()
    const navigate = useNavigate()
    dispatch({
        type: "PURCHASE_FETCHED",
        payload: null,
    })
    const purchaseReducer = useAppSelector<PurchaseReducerType>((state) => state.purchaseReducer)
    const [purchaseActive, setPurchaseActive] = useState<boolean>(purchaseReducer.purchase?.active ?? false)
    const theme = useTheme()
    const params = useParams()
    const [searchParams] = useSearchParams()

    // ------- Functions -------
    const activatePurchase = async (p: PurchaseModel): Promise<PurchaseModel> => {
        try {
            const res = await put(`${URL.Purchases.getPurchases}/${p.id}/activate`, {}, {headers: {Authorization: user?.token}})
            p.active = true
            if (!purchaseActive)
                setPurchaseActive(true)
            return p
        } catch (e: any) {
            if (e.response.status === 422)
                return Promise.reject(`Please connect required app accounts before activating this solution.`)
            else
                return Promise.reject(e.message)
        }
    }

    const createNewPurchase = () => {
        const newPurchase: PurchaseModel = {
            solutionId: solutionReducer.solution.id,
            active: false,
            appAccounts: [],
            solutionCode: solutionReducer.solution.solutionCode
        }
        post<PurchaseModel>(`${URL.Purchases.getPurchases}`, newPurchase, {headers: {Authorization: user?.token}})
            .then(p => {
                dispatch({type: "PURCHASE_FETCHED", payload: p})
                return p
            })
            .then(p => toast.error('Please connect required app-accounts.'))
            .catch((e: any) => {
                toast.error(e.message)
            })
    }

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()

        switch (!!(purchaseReducer.purchase?.active)) {
            case false:
                // Trying to activate the purchase
                if (!userReducer.user)
                    toast.error(`Please sign in before trying this solution.`)

                if (!purchaseReducer.purchase?.id) {
                    // Creating a new purchase for this user and this solution
                    createNewPurchase()
                    break
                }

                // Activating this purchase
                activatePurchase(purchaseReducer.purchase).catch((e: any) => {
                    toast.error(e)
                })
                break

            case true:
                // Pausing this purchase
                put(`${URL.Purchases.getPurchases}/${purchaseReducer.purchase!.id}/pause`, {}, {headers: {Authorization: user?.token}})
                    .then(_ => {
                        purchaseReducer.purchase!.active = false
                        if (purchaseActive)
                            setPurchaseActive(false)
                        return _
                    })
                    .catch((e: any) => {
                        console.log(JSON.stringify(e))
                        toast.error(e)
                    })
                break

            default:
                // Do nothing
                break
        }
    }

    const handleChangeIndex = (index: number) => {
        setValue(index)
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
        // let temp = ['overview','apps','scripts','examples','usage']
        // setSearchParams({tab: `${newValue}`})
    }

    const getPurchase = (solutionId: number) => {
        console.log('getPurchase is called.')
        get<PurchaseModel[] | PurchaseModel>(`${URL.Purchases.getPurchases}?solutionId=${solutionId}`, user?.token).then(res => {
                dispatch({
                    type: "PURCHASE_FETCHED",
                    payload: res,
                })
            }
        ).catch(e => {
            dispatch({
                type: "PURCHASE_FETCHED",
                payload: null,
            })
        })
    }

    const getCurrentProfileDataFromStrapi = () => {
        get<StrapiResponse>(URL.GetProfileTabData(currentSolution?.id, tab)).then(data => {
            setTabContent(data.data)
        })
    }

    const renderAppStatusIcon = (app: AppModel) => {
        // todo Get the status from Marketplace API
        if (currentSolution) {
            currentSolution.apps.forEach(
                app => statusIcons[app.appCode] = error_neutral
            )

            if (userReducer.isLogin && purchaseReducer.purchase && Object.keys(purchaseReducer.purchase).length > 0) {
                // todo status can be known by getting purchase, and seeing the app-accounts
                //    location1: solution.apps
                //    location2: purchase.appAccounts
                //    locations3: /appAccounts
                //    Unconnected: In location1, but not in other two
                //    Available: In location1 and location3, but not in location2
                //    Connected: In all 3 locations
                if (purchaseReducer.purchase?.appAccounts && purchaseReducer.purchase.appAccounts.length > 0) {
                    for (let appCode in statusIcons)
                        if (
                            purchaseReducer.purchase.appAccounts.filter(
                                (paymentApp) => paymentApp.appCode === appCode
                            ).length
                        )
                            statusIcons[appCode] = States
                }
            }
        }
        return <AppStatusIcon src={statusIcons[app.appCode]} loading="lazy" alt="Icon"/>
    }

    const getSolution = async () => get<SolutionModel>(URL.Solutions.getSolutionBySlug(params.id)).then((res) => {
        console.log('getPurchase is called.')
        dispatch({type: "GET_SOLUTIONS", payload: res})
        return res
    })

    // ------- UseEffects -------
    useEffect(() => {
        if (!userReducer.isLogin || userReducer.user === null)
            navigate('/sign-in')
    }, [userReducer])

    useEffect(() => {
        let hash = window.location.hash
        let temp = ["overview", "apps", "scripts", "examples", "usage"]

        if (hash) {
            hash = hash.replaceAll("#", "").replaceAll("_", " ").split("-")[1]
            if (hash) {
                setValue(temp.indexOf(hash))
                setTabs(hash)
            }
        }
        if (searchParams.get("tab") !== null) {
            const tabVal = Number(searchParams.get("tab"))
            setValue(tabVal)
            setTabs(temp[tabVal])
        }

        if (!purchaseReducer.purchase) {
            createNewPurchase()
        }
    }, [])

    useEffect(() => {
        if (solutionReducer.solution && Object.keys(solutionReducer.solution).length > 0) {
            setCurrentSolution(solutionReducer.solution)
            // Get data from Strapi whenever the tab is changed.
            getCurrentProfileDataFromStrapi()
        }
    }, [solutionReducer.solution, tab])

    useEffect(() => {
        getSolution().then(solution => {
            if (user?.token)
                getPurchase(solution.id)
        })
    }, [])

    return <React.Fragment>
        <Helmet title="Solution"/>
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={10} md={5}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        // flexDirection: "column",
                    }}
                    mb={3}
                >
                    <Box sx={{display: "flex"}}>
                        {currentSolution &&
                            currentSolution?.apps &&
                            currentSolution?.apps.map((app: AppModel, index: number) => (
                                <Box key={index} sx={{position: "relative"}}>
                                    <AppImage src={app?.iconUrl} alt={app?.name}/>
                                    {renderAppStatusIcon(app)}
                                </Box>
                            ))}
                    </Box>
                    <Typography variant="h3" gutterBottom display="inline">
                        {currentSolution && currentSolution?.name}
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
                display="flex"
                sx={{justifySelf: "flex-end", order: [2, 2, 1, 1]}}
                xs={12}
                md={5}
            >
                {/* <Grid item sx={{mt:5}}>
            <TaskProgress purchase={payment} solutions={solution} />
          </Grid> */}
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    // variant="fullWidth"
                    variant="scrollable"
                    orientation="horizontal"
                    allowScrollButtonsMobile
                    scrollButtons={true}
                    TabIndicatorProps={{
                        style: {
                            background: "transparent",
                        },
                    }}
                    TabScrollButtonProps={{
                        style: {
                            color: "white",
                        },
                    }}
                >
                    <SingleTab
                        {...a11yProps(0)}
                        label="Overview"
                        // icon={<RemoveRedEyeIcon />}
                        iconPosition="start"
                        onClick={() => setTabs("Overview")}
                    />
                    <SingleTab
                        label="Apps"
                        {...a11yProps(1)}
                        // icon={<AppsIcon />}
                        iconPosition="start"
                        onClick={() => setTabs("apps")}
                    />
                    <SingleTab
                        label="Scripts"
                        {...a11yProps(2)}
                        // icon={<DescriptionIcon />}
                        // icon={<img src={scriptIcon} style={{width:'27px', height:'27px'}}/>}
                        iconPosition="start"
                        onClick={() => setTabs("scripts")}
                    />
                    {/* <SingleTab
              label="Examples"
              {...a11yProps(3)}
              // icon={<TipsAndUpdatesOutlinedIcon />}
              iconPosition="start"
            /> */}
                    {/* <SingleTab
              label="Usage"
              {...a11yProps(4)}
              // icon={<SellOutlinedIcon />}
              iconPosition="start"
            /> */}
                </CustomTabs>
            </Grid>
            <Grid
                item
                display="flex"
                sx={{justifySelf: "flex-end", m: [3, 0], order: [1, 1, 2, 2]}}
                xs={12}
                sm={2}
                md={1}
            >
                {userReducer.isLogin && (
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                checked={purchaseActive}
                                onChange={handleChangeSwitch}
                                inputProps={{"aria-label": "controlled"}}
                            />
                        }
                        label={purchaseReducer.purchase?.active ? "On" : "Off"}
                    />
                )}
            </Grid>
        </Grid>
        <Divider my={6}/>
        {/*<Grid*/}
        {/*    container*/}
        {/*    justifyContent="space-between"*/}
        {/*    alignItems="center"*/}
        {/*    sx={{mb: 4}}*/}
        {/*>*/}
        {/*</Grid>*/}
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
        >
            {/* ------------- Tab Panel: Overview -------------*/}
            <TabPanel value={value} index={0}>
                <Overview tabContent={tabContent}/>
            </TabPanel>
            {/* ------------- Tab Panel: Apps ------------- */}
            <TabPanel value={value} index={1}>
                <ProfileApps purchaseId={purchaseReducer.purchase?.id}/>
                {/* <p>hello</p> */}
            </TabPanel>
            {/* ------------- Tab Panel: Solution Scripts ------------- */}
            <TabPanel value={value} index={2}>
                <SolutionScripts
                    tabContent={tabContent}
                    setTabContent={setTabContent}
                />
            </TabPanel>
            {/* ------------- Tab Panel: Examples ------------- */}
            {/* <TabPanel value={value} index={3}>
          Item Four
        </TabPanel> */}
            {/* ------------- Tab Panel: Usage Pricing ------------- */}
            <TabPanel value={value} index={4}>
                Item Five
            </TabPanel>
        </SwipeableViews>
    </React.Fragment>
}

export default Profile
