/* eslint-disable react-hooks/exhaustive-deps */
import styled from "@emotion/styled";
import {Slider} from "@mui/material";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {io} from "socket.io-client";
import useAppSelector from "../../hooks/useAppSelector";
import {Iplans} from "../../models/plan.model";
import PurchaseModel from "../../models/purchase.model";
import SolutionModel from "../../models/solution.model";
import UsageModel from "../../models/usage.model";
import URL from "../../services/Apis";
import {get} from "../../services/AxiosRequestMethods";
import {PurchaseReducerType} from "../../redux/reducers/types";

interface marks {
    label: string;
    value: number;
}

const StyledSlider = styled(Slider)({
    backgroundSize: 100,
    height: 10,
    "& .MuiSlider-track": {
        border: "none",
        backgroundColor: "transparent",
    },
    "& .MuiSlider-rail": {
        backgroundImage:
            "linear-gradient(to right, #17ff17 10%, #4444f1 50%, #f20606db 100%)",
    },

    "& .MuiSlider-thumb": {
        height: 15,
        width: 15,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
        "&:before": {
            display: "none",
        },
    },
    "& .MuiSlider-valueLabel": {
        lineHeight: 1,
        fontSize: 12,
        background: "unset",
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: "50% 50% 50% 0",
        backgroundColor: "#52af77",
        transformOrigin: "bottom left",
        transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
        "&:before": {display: "none"},
        "&.MuiSlider-valueLabelOpen": {
            transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
        },
        "& > *": {
            transform: "rotate(45deg)",
        },
    },
});

interface IProgress {
    rate: number;
    requests: number;
    currentVal: number | 1;
    label: string;
    color: string;
    free: number;
}

function TaskProgress(props: {
    purchase: PurchaseModel | undefined;
    solutions: SolutionModel | undefined;
    isScroll?: boolean;
    refreshUsage: UsageModel | null;
}) {
    const {refreshUsage} = props;
    const [usage, setUsage] = useState<UsageModel>();
    const [, setProgressArray] = useState<IProgress[]>([]);
    const [plan, setPlan] = useState<Iplans[] | any>([]);
    // const current = usage?.requests || 0;
    const [current, setCurrent] = useState(0);
    const [customMarks, setCustomMarks] = useState<marks[] | boolean>(false);
    const [percentage, setPercentage] = useState(0);
    const purchaseReducer = useAppSelector<PurchaseReducerType>((state) => state.purchaseReducer)
    const user = useAppSelector((state) => state.userReducer.user);
    const loginStatus = useAppSelector((state) => state.userReducer.isLogin);

    const socket = io("https://api.infinityweb.app", {
        auth: {token: user?.token},
    });

    socket.on("usage", (arg) => {
        setCurrent(arg);
    });

    useEffect(() => {
        if (refreshUsage) setCurrent(refreshUsage?.requests);
    }, [refreshUsage]);

    useEffect(() => {
        socket.connect();

        if (purchaseReducer.purchase?.id)
            socket.emit("purchase", purchaseReducer.purchase?.id)

        return () => {
            socket.on("disconnect", () => {
                console.log("socket disconnected", socket.id); // undefined
            });
            socket.close();
        };
    }, [])

    const fetchUsage = () => {
        get<UsageModel>(URL.Usage.list(purchaseReducer.purchase?.id, new Date()), user?.token)
            .then((usage) => setUsage(usage[0]))
            .catch((err: Error) => console.error({err}))
    }

    useEffect(() => {
        if (purchaseReducer.purchase?.id) fetchUsage()
    }, [purchaseReducer.purchase])

    useEffect(() => {
        if (usage?.requests)
            setCurrent(usage.requests)
    }, [usage])

    const getPlanData = () => {
        get<Iplans>(URL.Plan.getPlan).then((data) => {
            setPlan(data)
        })
    }
    useEffect(() => {
        getPlanData()
    }, [])

    const plansRes = plan || []

    const getPlanById = (id: number) => {
        const [filtered] = plansRes?.filter((item: Iplans) => item?.id === id)
        return filtered
    }

    const result =
        props?.solutions?.planId && getPlanById(props?.solutions?.planId);

    useEffect(() => {
        if (result && loginStatus) {
            setPercentage(current)
            const marks = [
                {
                    value: result.requests1,
                    label: "Free",
                },
                {
                    value: result.requests2,
                    label: "Plan 2",
                },
                {
                    value: result.requests3,
                    label: "Plan 3",
                },
            ];
            setCustomMarks(marks);
        }
    }, [result, current]);

    useEffect(() => {
        if (current && result) {
            const newConstructedProgress: any = [];

            for (let i = 1; i <= 3; i++) {
                const currentValue = () => {
                    if (result?.[`requests${i - 1}`]) {
                        if (
                            current > result?.[`requests${i - 1}`] &&
                            current <= result?.[`requests${i}`]
                        ) {
                            return current;
                        } else if (current < result?.[`requests${i}`]) {
                            return 0;
                        } else {
                            return current;
                        }
                    } else {
                        return current;
                    }
                };

                newConstructedProgress.push({
                    rate: result?.[`rate${i}`],
                    requests: result?.[`requests${i}`],
                    label: i === 1 ? "free" : i === 2 ? "plan 2" : "plan 3",
                    color: i === 1 ? "#FFC78B" : i === 2 ? "#78D0FE" : "#623CF4",
                    bgColor: i === 1 ? "#e5e5e5" : i === 2 ? "#d1cdcd" : "#e5e5e5",
                    current: currentValue(),
                    free:
                        currentValue() > result?.[`requests${i}`]
                            ? result?.[`requests${i}`]
                            : currentValue(),
                });
            }
            setProgressArray(newConstructedProgress as any);
        }
    }, [current, result]);

    // const calculateAverage = (
    //   CurrentReq: number,
    //   totalValue: number,
    //   free: number
    // ) => {
    //   const _percentage = (CurrentReq / totalValue) * 100;
    //   return _percentage > 100 ? 100 : _percentage;
    // };

    // fixme What's the parameter doing here
    function valuetext() {
        return `${percentage}%`;
    }

    return (
        <Box sx={{margin: "0 auto", position: "relative", width: "80%"}}>
            <StyledSlider
                aria-label="pretto slider"
                value={percentage}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay="auto"
                marks={customMarks}
                componentsProps={{
                    markLabel: {
                        style: {
                            color: "white",
                        },
                    },
                    mark: {
                        style: {
                            color: "white",
                        },
                    },
                }}
                min={0}
                max={result?.requests3 ? result?.requests3 : 100}
            />
        </Box>
    );
}

export default TaskProgress;
