import styled from "@emotion/styled";
import {Formik} from "formik";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import useAppSelector from "../../hooks/useAppSelector";
import UserModel from "../../models/user.model";
import SocialMediaLogin from "./SocialMediaLogin";

import {Alert as MuiAlert, Box, Button, TextField as MuiTextField, Typography,} from "@mui/material";
import {spacing} from "@mui/system";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import URL from "../../services/Apis";
import {post} from "../../services/AxiosRequestMethods";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Divider = styled(Box)`
  font-size: 1.3rem;
  color: #607b8b;
  display: flex;
  align-items: center;
`;

interface Values {
  email: string;
  password: string;
}

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.userReducer);

  const handleSubmit = async (values: Values) => {
    let dataToSend = {
      headers: {
        "Content-Type": "application/json",
      },
      email: values.email,
      password: values.password,
    };
      post(URL.Users.login, dataToSend).then((data: UserModel | any) => {
          if (data && data?.deactivatedOn === null) {
              dispatch({
                  type: "LOGIN",
                  payload: data,
              })

              localStorage.setItem("isLogin", "true")
              if (user?.goBack)
                  navigate(-1)
              else
                  navigate("/solutions")
          } else
              toast.success("User account deactivated!")
      }).catch(err => {
          toast.error("Invalid Username or Password", err)
      })
  };
  return (
    <Formik
        initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values) => handleSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
            />
              {/*<FormControlLabel*/}
              {/*  control={<Checkbox value="remember" color="primary" />}*/}
              {/*  label="Remember me"*/}
              {/*/>*/}
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
              >
                  Sign in
              </Button>
              <Button
              component={Link}
              to="/reset-password"
              fullWidth
              color="primary"
            >
              Forgot password?
            </Button>
            <Button component={Link} to="/sign-up" fullWidth color="primary">
              Sign Up
            </Button>
          </form>

          <Box sx={{ margin: "2rem 0" }}>
            <Divider>
              <hr style={{ width: "100%" }} />
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ padding: "0 1rem" }}
              >
                OR
              </Typography>
              <hr style={{ width: "100%" }} />
            </Divider>
          </Box>
          {/* Google & GitHub Sign-in Component */}
          <SocialMediaLogin />
        </React.Fragment>
      )}
    </Formik>
  );
}

export default SignIn;
