import styled from "@emotion/styled";
import * as React from "react";
import {useNavigate} from "react-router-dom";

import {Avatar, Grid, Menu, MenuItem, Typography,} from "@mui/material";
import {useDispatch} from "react-redux";
import {useGoogleLogout} from "react-google-login";
import useAppSelector from "../../hooks/useAppSelector";

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.userReducer.user);
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const googleLogoutSuccess = () => {
    console.log("success");
  };

  const googleLogoutFailure = () => {
    console.log("google logout failed");
  };

  const { signOut } = useGoogleLogout({
    onFailure: googleLogoutFailure,
    clientId:
      "437387893940-qgb2f5okrgceb9nlb434j9ov7ovetj57.apps.googleusercontent.com",
    cookiePolicy: "single_host_origin",
    onLogoutSuccess: googleLogoutSuccess,
  });

  const handleSignOut = async () => {
    dispatch({
      type: "LOGOUT",
    });
    localStorage.setItem("isLogin", "false");

    dispatch({
        type: "PURCHASE_FETCHED",
        payload: {},
    });
    signOut();
    if (localStorage.getItem("gh_state")) {
      localStorage.removeItem("gh_state");
    }
    navigate("/sign-in");
  };

  return (
    <React.Fragment>
      <Grid
        onClick={toggleMenu}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          cursor: "pointer",
        }}
      >
        <Avatar
          alt={user?.name}
          src={
            user?.profileObj?.imageUrl
              ? user?.profileObj?.imageUrl
              : user?.photoUrl
          }
          sx={{ margin: "0 10px" }}
        />
        {/* </FooterBadge> */}
        <Grid item>
          {!!user && (
            <FooterText variant="body2">
              {user.profileObj ? user.profileObj.name : user.name}
            </FooterText>
          )}
        </Grid>
      </Grid>
      <Menu
        id="menu-appBar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem
            onClick={() => {
              navigate("/settings");
              closeMenu();
            }}
        >
          Settings
        </MenuItem>
        <MenuItem
            // todo Test async-await here
            onClick={async () => {
              await handleSignOut();
              closeMenu();
            }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
