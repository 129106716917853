import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from '../redux/store';

const HomeHandler = () => {
    const user = useSelector((state: RootState) => state.userReducer);
    const navigate = useNavigate();

    if (user && user.isLogin && Object.keys(user).length !== 0) {
        navigate('/solutions');
        return <div>
            Loading...
        </div>
    } else {
        navigate('/sign-in');
        return <div>
            Loading...
        </div>
    }
};

export default HomeHandler;
