import React from "react";
import styled from "@emotion/styled";
import {withTheme} from "@emotion/react";
import Chart from "react-apexcharts";
import {ApexOptions} from "apexcharts";

import {Card as MuiCard, CardContent, Typography} from "@mui/material";
import {spacing} from "@mui/system";

import {ThemeProps} from "../../../types/theme";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;

const HeatmapChart = ({ theme }: ThemeProps) => {
  const generateData = (count: any, yrange: any) => {
    let i = 0;
    const series = [];
    while (i < count) {
      const x = (i + 1).toString();
      const y =
          Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      // series.push({
      //   x: x,
      //   y: y,
      // });
      i++;
    }
    return series;
  };

  const data = [
    {
      name: "Metric1",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric2",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric3",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric4",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric5",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric6",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric7",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric8",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
    {
      name: "Metric9",
      data: generateData(20, {
        min: 0,
        max: 90,
      }),
    },
  ];

  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
    },
    colors: [
      theme.palette.primary.light,
      theme.palette.success.light,
      theme.palette.warning.light,
      theme.palette.error.light,
      theme.palette.info.light,
    ],
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Heatmap Chart
        </Typography>
        <Typography variant="body2" gutterBottom>
          Heatmap is a visualization tool that employs color the way a bar chart
          employs height and width in representing data.
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="heatmap" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(HeatmapChart);
