import {Divider, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import URL from "../../services/Apis";
import {get} from "../../services/AxiosRequestMethods";

import AppsComponent from "../components/AppsComponent";
import {AppModel} from "../../models/app.model";

function AvailableApps() {
    const [apps, setApps] = useState<AppModel[]>([]);
    const [, setLoading] = useState<boolean>(false);
    // const user = useAppSelector((state) => state.userReducer.user);
    // const payment = useAppSelector((state) => state.PaymentReducer.payment);

    useEffect(() => {
        getAppsData()
    }, [])

    const getAppsData = () => {
        setLoading(true);
        get<{ data: AppModel[], pagination: { count: number, totalPages: number, pageSize: number, pageNumber: number } }>(URL.App.GetApps).then(r => {
            setApps(r.data)
            setLoading(false)
        }).catch(e => {
            console.error("---> errors", e)
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            <Helmet title="App accounts"/>

            <Typography variant="h3" gutterBottom display="inline">
                Available Apps
            </Typography>
            <Divider sx={{my: 2}}/>
            <AppsComponent appsData={apps} status={false}/>
        </React.Fragment>
    );
}

export default AvailableApps;
