import styled from "@emotion/styled";
import {Divider as MuiDivider, Grid, Typography} from "@mui/material";
import {spacing} from "@mui/system";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router-dom";
import AccountSettings from "../../components/AccountSettings";
import Billing from "../../components/Billing";
import MySettings from "../../components/MySettings";
import useAppSelector from "../../hooks/useAppSelector";

const Divider = styled(MuiDivider)(spacing);

function Settings() {
    const userReducer = useAppSelector((state) => state.userReducer)
    const navigate = useNavigate();

    useEffect(() => {
        if (!userReducer.isLogin || userReducer.user === null)
            navigate("/sign-in")
    }, [])

    return (
        <React.Fragment>
            <Helmet title="Settings"/>

            <Typography variant="h3" gutterBottom display="inline">
                Settings
            </Typography>

            {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs> */}

            <Divider my={6}/>

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <MySettings/>
                    <Billing/>
                    {/* <Notifications />
          */}
                    <AccountSettings/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Settings;
