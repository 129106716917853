const isDev = false;
let baseUrl = "https://api.infinityweb.app";
const localUrl = "http://localhost:4000";
baseUrl = isDev ? localUrl : baseUrl
const strapiBaseUrl = "https://strapi.infinityweb.app/api";

const URL = {
    //  App Accounts
    App: {
        GetApps: `${baseUrl}/apps`,
    },

    AppAccount: {
        GetAppAccountByID: `${baseUrl}/appAccounts`,
        getAllAppAccounts: `${baseUrl}/appAccounts`,
        CreateAppAccount: `${baseUrl}/appAccounts`,
        EditAppAccountByID: `${baseUrl}/appAccounts`,
        DeleteAppAccountByID: `${baseUrl}/appAccounts`,
        ConnectAppAccount: `${baseUrl}/purchases`,
    },

    // Users
    Users: {
        login: `${baseUrl}/users/login`,
        update: `${baseUrl}/users/me`,
        register: `${baseUrl}/users/register`,
        forgotPassword: `${baseUrl}/users/forgot`,
        resetPassword: `${baseUrl}/users/reset-password`,
        changePassword: `${baseUrl}/users/change-password`,
        uploadUserPhoto: `${baseUrl}/users/profile-photo`,
        logoutAll: `${baseUrl}/users/logout-all`,
        deactivate: `${baseUrl}/users/deactivate`,
    },

    // Usage
    Usage: {
        list: (purchaseId?: number, date?: Date) => {
            let url = `${baseUrl}/usage?`
            if (purchaseId)
                url = `${url}purchase=${purchaseId}&`

            if (date) {
                const offset = date.getTimezoneOffset()
                date = new Date(date.getTime() - (offset * 60 * 1000))
                url = `${url}date=${date.toISOString().split('T')[0]}`
            }
            return url
        }
    },

    // Invoice
    Invoice: {
        getBillingInvoice: `${baseUrl}/invoices`,
    },

    //Plans
    Plan: {
        getPlan: `${baseUrl}/plans`,
    },

    // Get Profile Data From Query fn
    GetProfileTabData: (id: number, tab: string) =>
        `https://strapi.infinityweb.app/api/solution-profiles?filters[profile_id][$eq]=${id}&populate[anchors][filters][tab][$eq]=${tab}&populate[drop_downs][filters][tab][$eq]=${tab}&populate[drop_downs][populate][1]=anchors.scripts&populate[anchors][populate][0]=scripts`,

    // Solutions
    Solutions: {
        getSolutions: `${baseUrl}/solutions`,
        getSolutionBySlug: (slug?: string) => `${baseUrl}/solutions/${slug}`,
    },

    // Scripts
    Scripts: {
        getScripts: `${strapiBaseUrl}/scripts`,
    },

    // Purchase
    Purchases: {
        getPurchaseByID: `${baseUrl}/purchases?id=`,
        getPurchases: `${baseUrl}/purchases`,
    },

    // update userPreferences
    userPreferences: {
        preferences: `${baseUrl}/userPreferences`,
    },

    billing: {
        getBillingPeriod: `${baseUrl}/billingPeriods`,
        getBillingById: `${baseUrl}/billingPeriods/`,
    },

    stripe: {
        createCustomer: `${baseUrl}/stripe/createCustomer`,
        listPaymentMethod: `${baseUrl}/users/payment-method`,
        userPaymentMethod: `${baseUrl}/users/payment-method`,
        delPaymentMethod: `${baseUrl}/users/payment-method`,
    },
};

export default URL;
