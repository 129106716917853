import {PurchaseReducerType} from "./types"

const initialState: PurchaseReducerType = {purchase: undefined}

const purchaseReducer = (state = initialState, {type, payload}) => {
    if (!payload)
        return state

    switch (type) {
        case "PURCHASE_FETCHED":
            if (Array.isArray(payload) && payload.length && Object.keys(payload[0]).length)
                return {...state, purchase: payload[0]}
            else if (Object.keys(payload).length)
                return {...state, purchase: payload}
            else
                return state
        default:
            return state
    }
}

export default purchaseReducer
