import styled from "@emotion/styled";
import {CloudUpload as MuiCloudUpload} from "@mui/icons-material";
import {
    Avatar,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    FormControl as MuiFormControl,
    Grid,
    TextField as MuiTextField,
    Typography
} from "@mui/material";
import {spacing, SpacingProps} from "@mui/system";
import {useFormik} from "formik";
import {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import useAppSelector from "../../hooks/useAppSelector";
import API from "../../services/Apis";
import {post, put} from "../../services/AxiosRequestMethods";
import Loader from "../Loader";

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)<{ my?: number }>(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
    component?: string;
}

interface IImage {
    message?: string;
    url?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function MySettings() {

    const user = useAppSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            id: user?.id,
            username: user?.name,
            pronouns: user?.pronouns,
            email: user?.email,
            about: user?.about,
        },
        validationSchema: Yup.object({
            username: Yup.string(),
            pronouns: Yup.string(),
            email: Yup.string().email("Invalid email address"),
            about: Yup.string(),
        }),
        onSubmit: async (values) => {
            const config = {
                url: API.Users.update,
                method: "PUT",
                headers: {
                    Authorization: user?.token,
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    id: user?.id,
                    name: values.username,
                    email: values.email,
                },
            };
            await put(config.url, config.data, {
                headers: {
                    Authorization: `${user?.token}`,
                },
            });
            dispatch({
                type: "UPDATE",
                payload: {
                    ...user,
                    name: formik.values.username,
                    // pronouns: formik.values.pronouns,
                    email: formik.values.email,
                    // about: formik.values.about,
                },
            });
            toast.success(`User updated successfully.`);
        },
    });

    const updateImage = async (payload: File) => {
        setLoadingStatus(true);
        const reader = new FileReader()

        const validTypes = ["image/jpeg", "image/jpg", "image/png"];
        let flag = validTypes.includes(payload?.type)

        reader.onloadend = function async() {
            const config = {
                url: API.Users.uploadUserPhoto,
                method: "PUT",
                headers: {
                    Authorization: user?.token,
                    "Content-Type": flag ? payload?.type : toast.error("wrong type"),
                },
                data: payload,
            };
            setTimeout(() => {
                if (flag) {
                    return post(config.url, config.data, {
                        headers: config.headers,
                    }).then((image: IImage | any) => {
                        dispatch({
                            type: "UPDATE",
                            payload: {...user, photoUrl: image?.url},
                        });

                        setLoadingStatus(false);
                    });
                } else {
                    return;
                }
            }, 1500);
        };

        await reader.readAsDataURL(payload);
    };

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement> | any) => {
        updateImage(event.currentTarget!.files[0]);
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Account info
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={6}>
                        <Grid item md={8}>
                            <FormControl fullWidth my={2} variant="outlined">
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Name"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    my={2}
                                />
                                <TextField
                                    label="Email"
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                />
                                {formik.errors.email && (
                                    <Typography sx={{color: "red"}}>
                                        {formik.errors.email}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={4}>
                            <CenteredContent>
                                {loadingStatus ? (
                                    <>
                                        <Loader/>
                                        <br/>
                                        <br/>
                                    </>
                                ) : (
                                    <BigAvatar
                                        alt="Remy Sharp"
                                        src={
                                            user?.profileObj?.imageUrl
                                                ? user?.profileObj?.imageUrl
                                                : user?.photoUrl
                                                    ? user?.photoUrl
                                                    : undefined
                                        }
                                    />
                                )}
                                <input
                                    accept="image/*"
                                    style={{display: "none"}}
                                    id="raised-button-file"
                                    multiple
                                    type="file"
                                    onChange={handleChange}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" color="primary" component="span">
                                        <CloudUpload mr={2}/> Upload
                                    </Button>
                                </label>
                            </CenteredContent>
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary" mt={3} type="submit">
                        Save changes
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
}

export default MySettings;
