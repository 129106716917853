import styled from "@emotion/styled";
import {Box, Typography} from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import React, {FC, useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import SideTabComp from "../SideTabComp";
import "./markdownStyles.css";
import TabModel from '../../../models/StrapiModels/tab.model'
import AnchorModel from '../../../models/StrapiModels/anchor.model'

interface Props {
    tabContent: TabModel[];
}

const Solutions = styled(Box)`
  flex-grow: 1;
  display: flex;
`;

const TabContent = styled(Box)`
  padding: 1rem 2rem;
  width: 80%;
  overflow-y: auto;
`;

const Overview: FC<Props> = ({tabContent}) => {
    const [overviewStrapiData, setOverviewStrapiData] = useState<AnchorModel>()
    const [overviewTabData, setOverviewTabData] = useState<TabModel[]>([])

    useEffect(() => {
        if (tabContent.length > 0) {
            // todo sort anchors by position
            for (let tc of tabContent)
                tc.attributes.anchors?.data.sort((a, b) => a.attributes.position - b.attributes.position)

            setOverviewStrapiData(tabContent[0]?.attributes?.anchors?.data[0]);
            setOverviewTabData(tabContent);
        }
    }, [tabContent]);

    useEffect(() => {
        let hash = window.location.hash;
        let found = false;
        if (hash) {
            hash = hash.replaceAll("#", "").replaceAll("_", " ").split("-")[0];

            // todo check if flat is available
            const anchor = tabContent?.map(tc => tc.attributes.anchors?.data).flat(1)
                .find(anchor => hash === anchor?.attributes?.title?.replace(/[^a-zA-Z ]/g, "").trim())
            if (anchor) {
                setOverviewStrapiData(anchor)
                found = true
            }

            // tabContent?.map((script: any) => {
            //   script.attributes.anchors.data.map((anchor: any, index: number) => {
            //     if (
            //       hash === anchor?.attributes?.title?.replace(/[^a-zA-Z ]/g, "").trim()
            //     ) {
            //       setOverviewStrapiData(anchor);
            //       found = true;
            //     }
            //   });
            // });

            if (!found) {
                const dropDown = tabContent?.map(tc => tc.attributes.drop_downs?.data).flat(1)
                    .map(variation => variation?.attributes.anchors.data).flat(1)
                    .find(dropDown => hash === dropDown?.attributes?.title?.replace(/[^a-zA-Z ]/g, "").trim())
                if (dropDown)
                    setOverviewStrapiData(dropDown)

                // tabContent?.map((script: any) => {
                //   script.attributes.drop_downs.data.map(
                //     (variation: any, index: number) => {
                //       variation.attributes.anchors.data.map(
                //         (dropDown: any, idx: number) => {
                //           if (
                //             hash ===
                //             dropDown?.attributes?.title
                //               ?.replace(/[^a-zA-Z ]/g, "")
                //               .trim()
                //           ) {
                //             setOverviewStrapiData(dropDown);
                //           }
                //         }
                //       );
                //     }
                //   );
                // });

            }
        }
    }, [tabContent]);

    const splitStringWithTags = (str: string) => str?.split("\n").filter(txt => txt !== "")

    const getVideo = (content?: any) => {
        let innerData: string[] | null = content?.match(/<vimeo>(.*?)<\/vimeo>/g);

        return innerData !== null && innerData?.length > 0 ? (
            <Vimeo
                video={innerData[0]?.replace(/<\/?vimeo>/g, "")}
                autoplay
                className={"vimeo"}
            />
        ) : null;
    };

    return (
        <React.Fragment>
            {overviewTabData &&
            overviewTabData.length > 0 &&
            overviewTabData[0].attributes.anchors && overviewTabData[0].attributes.anchors.data?.length > 0 ? (
                <Solutions>
                    <SideTabComp
                        tabContent={tabContent}
                        onItemsClick={(anchor) => {
                            setOverviewStrapiData(anchor);
                            window.location.hash = `#${anchor.attributes.title.replaceAll(
                                " ",
                                "_"
                            )}-${anchor?.attributes?.tab}`;
                        }}
                        onSubItemsClick={(dropDown) => {
                            setOverviewStrapiData(dropDown);
                            window.location.hash = `#${dropDown.attributes.title
                                .replace(/[^a-zA-Z ]/g, "")
                                .trim()
                                .replaceAll(" ", "_")}-${dropDown?.attributes?.tab}`;
                        }}
                    />
                    <TabContent>
                        {splitStringWithTags(overviewStrapiData?.attributes.content ?? "")?.map(
                            (content, index) => (
                                <React.Fragment key={index}>
                                    {content?.startsWith("<vimeo>") &&
                                    content?.endsWith("</vimeo>") ? (
                                        getVideo(content)
                                    ) : (
                                        <React.Fragment>
                                            <ReactMarkdown linkTarget='_blank' className="markdown_container">
                                                {content}
                                            </ReactMarkdown>
                                            <br/>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )
                        )}
                    </TabContent>
                </Solutions>
            ) : (
                <Typography variant="h2">No Data For Overview</Typography>
            )}
        </React.Fragment>
    );
};

export default Overview;
