import styled from "@emotion/styled";
import {Box, Typography,} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import {Ilang} from "../../../models/script";
import URL from "../../../services/Apis";
import {get} from "../../../services/AxiosRequestMethods";
import SideTabComp from "../SideTabComp";
import ScriptContent from "./ScriptContent";

interface Props {
    setTabContent?: Function
    tabContent: any
}

interface script {
    attributes: {
        Visibility: string;
        content: string;
        createdAt: Date;
        position: number;
        publishedAt: Date;
        scripts: {
            data: {
                attributes: any;
            }[];
        };
        tab: string;
        title: string;
        unique_title: string;
        updatedAt: Date;
    };
}

const Solutions = styled(Box)`
  flex-grow: 1;
  display: flex;
`;

const TabContent = styled(Box)`
  padding: 1rem 2rem;
  width: 80%;
  overflow-y: auto;
`;

const SolutionScripts: FC<Props> = ({tabContent}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [videoLink, setVideoLink] = useState<string>("");
    const [scriptContent, setScriptContent] = useState<script>();
    const [newScriptData, setNewScriptData] = useState<Ilang[] | any>([]);
    const [language, setLanguage] = useState<string>("");
    const [script, setScript] = useState<{}>({});
    const [value, setValue] = useState<number>(0);
    let token = useAppSelector((state) =>
        state?.purchaseReducer.purchase ? state?.purchaseReducer?.purchase?.token : ""
    );
    const [paymentToken, setPaymentToken] = useState(token)
    const user = useAppSelector((state) => state?.userReducer?.user)

    useEffect(() => {
        let hash = window.location.hash;
        let found = false;
        if (hash) {
            hash = hash.replaceAll("#", "").replaceAll("_", " ").split("-")[0];
            tabContent?.map((script: any) => {
                script.attributes.anchors.data.map((anchor: any, index: number) => {
                    if (
                        hash === anchor.attributes.title.replace(/[^a-zA-Z ]/g, "").trim()
                    ) {
                        setCurrentIndex(index);
                        setScriptContent(anchor);
                        found = true;
                    }
                });
            });

            if (!found) {
                // fixme remove flat
                // const dropDown:{dd:any, i:number} = tabContent?.map(tc => tc.attributes.drop_downs.data).flat(1)
                //     .map((variation:any, index:number) => {
                //       return {dd: variation.attributes.anchors.data, i: index}
                //     }).find((dropDown: {dd:any, i:number}) => hash === dropDown?.dd?.attributes?.title?.replace(/[^a-zA-Z ]/g, "").trim())
                // if(dropDown)
                //   handleSingleTabClick(dropDown.dd, dropDown.i)

                tabContent?.map((script: any) => {
                    script.attributes.drop_downs.data.map(
                        (variation: any, index: number) => {
                            variation.attributes.anchors.data.map(
                                (dropDown: any, idx: number) => {
                                    if (
                                        hash ===
                                        dropDown.attributes.title.replace(/[^a-zA-Z ]/g, "").trim()
                                    ) {
                                        handleSingleTabClick(dropDown, index);
                                    }
                                }
                            );
                        }
                    );
                });
            }
        }
    }, [scriptContent]);

    const parseContentToGetId = (content: string): number | null => {
        let currentScriptId: {} | any = /<script(.*?)<\/script>/g.exec(content);
        if (!currentScriptId)
            return null

        currentScriptId = currentScriptId[1].match(/(\d+)/);
        return parseInt(currentScriptId[1]);
    };

    const parseContentToGetVideoLink = (content: string): null | void => {
        const videoLink = /<vimeo>(.*?)<\/vimeo>/g.exec(content);
        if (videoLink)
            setVideoLink(videoLink[1])
    }

    const getScripts = (id?: number | null) => {
        get(`${URL.Scripts.getScripts}/${id}`).then(scriptById => {
            setNewScriptData(scriptById);
            getScriptsDataByLanguage(scriptById);
        })
    };

    const handleSingleTabClick = (dropDown: any, index: number) => {
        let id: number | null = parseContentToGetId(dropDown.attributes.content)
        if (id) getScripts(id)
        parseContentToGetVideoLink(dropDown.attributes.content)
        setCurrentIndex(index + 1)
        setScriptContent(dropDown)
        setNewScriptData(dropDown)
    };

    const getScriptsDataByLanguage = (scriptById?: any) => {
        if (scriptById?.data?.attributes !== undefined) {
            if (value === 0) {
                if (scriptById?.data?.attributes[language]) {
                    let innerData: string[] = scriptById?.data?.attributes[
                        language
                        ]?.match(/<token>(.*?)<\/token>/g);

                    if (innerData !== null && user) {
                        let ReplacedTag = scriptById?.data?.attributes[language].replace(
                            innerData[0],
                            paymentToken
                        );
                        setScript(ReplacedTag);
                    } else
                        setScript(scriptById?.data?.attributes[language]);
                } else
                    setScript(`No script yet for ${language}`)
            }
            if (value === 1)
                setScript(scriptById?.data?.attributes.response)

            if (value === 2)
                setScript(scriptById?.data?.attributes.test_payload)
        }
    };

    useEffect(() => {
        if (tabContent[0])
            setScriptContent(tabContent[0]?.attributes?.anchors?.data[0]);
    }, [tabContent]);

    useEffect(() => {
        if (token)
            setPaymentToken(token)
        else
            setPaymentToken("No Token Yet")
    }, [token, currentIndex])

    useEffect(() => {
        setLanguage("javascript");
        if (newScriptData?.javascript) {
            setScript(newScriptData[language]);
        }
    }, [newScriptData, currentIndex]);

    useEffect(() => {
        getScriptsDataByLanguage(newScriptData)
    }, [paymentToken, language, currentIndex, value])

    return (
        <React.Fragment>
            {tabContent[0]?.attributes?.anchors?.data?.length === 0 &&
            tabContent[0]?.attributes?.drop_downs?.data?.length === 0 ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="40vh"
                >
                    <Typography variant="h2">No scripts available!</Typography>
                </Box>
            ) : (
                <Solutions>
                    <SideTabComp
                        tabContent={tabContent}
                        onItemsClick={(anchor: any, index: number) => {
                            setCurrentIndex(index);
                            setScriptContent(anchor);
                            window.location.hash = `#${anchor.attributes.title
                                .replace(/[^a-zA-Z ]/g, "")
                                .trim()
                                .replaceAll(" ", "_")}-${anchor.attributes.tab}`;
                        }}
                        onSubItemsClick={(dropDown: any, index: number) => {
                            handleSingleTabClick(dropDown, index);
                            window.location.hash = `#${dropDown.attributes.title
                                .replace(/[^a-zA-Z ]/g, "")
                                .trim()
                                .replaceAll(" ", "_")}-${dropDown.attributes.tab}`;
                        }}
                    />
                    <TabContent sx={{mt: "-15px"}} key={currentIndex}>
                        <Box>
                            <ScriptContent
                                scriptContent={scriptContent}
                                currentIndex={currentIndex}
                                token={paymentToken}
                                setPaymentToken={setPaymentToken}
                                language={language}
                                setLanguage={setLanguage}
                                script={script}
                                setScript={setScript}
                                newScriptData={newScriptData}
                                setNewScriptData={setNewScriptData}
                                videoLink={videoLink}
                                value={value}
                                setValue={setValue}
                            />
                        </Box>
                    </TabContent>
                </Solutions>
            )}
        </React.Fragment>
    );
};

export default SolutionScripts;
