import {Formik} from "formik";
import React from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import styled from "@emotion/styled";
import {Alert as MuiAlert, Box, Button, TextField as MuiTextField, Typography,} from "@mui/material";

import {spacing} from "@mui/system";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import URL from "../../services/Apis";
import {post} from "../../services/AxiosRequestMethods";
import SocialMediaLogin from "./SocialMediaLogin";

// import useAuth from "../../hooks/useAuth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface Values {
  email: string;
  password: string;
}

const Divider = styled(Box)`
  font-size: 1.3rem;
  color: #607b8b;
  display: flex;
  align-items: center;
`;

function SignUp() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // todo test async-await removed
    const handleSubmit = (values: Values) => {
        const dataToSend = {
            email: values.email,
            password: values.password,
        };
        post(URL.Users.register, dataToSend)
            .then((user) => {
                dispatch({
                    type: "REGISTER",
                    payload: user,
                });
        navigate("/solutions");
      })
      .catch(() => {
        toast.error("User already exists.");
      });
  };
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255),
        lastName: Yup.string().max(255),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(7, "Must be at least 7 characters")
          .max(255)
          .required("Required"),
        confirmPassword: Yup.string()
          .when("password", {
              is: (val: any) => !!(val && val.length > 0),
              then: Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Both password need to be the same"
              ),
          })
          .required("Required"),
      })}
      onSubmit={async (values) => {
        handleSubmit(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </form>
          <Box sx={{ margin: "2rem 0" }}>
            <Divider>
              <hr style={{ width: "100%" }} />
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ padding: "0 1rem" }}
              >
                OR
              </Typography>
              <hr style={{ width: "100%" }} />
            </Divider>
          </Box>
          {/* Google & Github Sign-in Component */}
          <SocialMediaLogin />
          <Typography
            style={{ textAlign: "center", color: "grey", fontSize: "12px" }}
            mt={6}
          >
            By signing up, you agree to the <Links>Terms of service</Links>{" "}
            and&nbsp;
            <Links>Privacy Policy.</Links>
          </Typography>

          <Typography
            style={{ textAlign: "center", color: "grey", fontSize: "15px" }}
            mt={3}
          >
            Already have an account?&nbsp;
            <span
              style={{
                color: "#376fd0",
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate("/sign-in")}
            >
              Sign in
            </span>
          </Typography>
        </React.Fragment>
      )}
    </Formik>
  );
}

const Links = styled.span`
  font-weight: 600;
  border-bottom: 1px solid;
`;
export default SignUp;
