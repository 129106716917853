import "@mui/lab/themeAugmentation";

import {createTheme as createMuiTheme} from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import shadows from "./shadows";

const createTheme = (name: string) => {
    let themeConfig = variants.find((variant) => variant.name === name);

    if (!themeConfig) {
        console.warn(new Error(`The theme ${name} is not valid`));
        themeConfig = variants[0];
    }

    // @ts-ignore
    return createMuiTheme(
        {
            spacing: 4,
            breakpoints,
            // components: components,
            typography,
            shadows,
            palette: themeConfig.palette,
        },
        themeConfig
    );
};

export default createTheme;
