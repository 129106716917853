import {Button} from "@mui/material";
import React, {FC, useEffect} from "react";
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline,} from "react-google-login";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {gapi} from "gapi-script";

import googleLogo from "../../assets/icons/google-logo-1.png";
import {post} from "../../services/AxiosRequestMethods";
import URL from "../../services/Apis";

const GoogleLoginComp: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_AUTH0_CLIENT_ID,
                scope: "email",
            });
        }

        gapi.load("client:auth2", start);
    }, []);

    const onGoogleSuccess = async (
        googleData: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
        const res1 = googleData as GoogleLoginResponse;
        const tokenId = res1.tokenId;
        let dts = {
            headers: {
                "Content-Type": "application/json",
            },
            provider: "google",
            idToken: tokenId,
        };
        await post(URL.Users.login, dts).then((res) => {
            if (googleData) {
                dispatch({type: "LOGIN", payload: res});
                navigate("/solutions");
            }
        });
    };

    const onGoogleFailure = (error: Error) => {
        console.log("Google login attempt failed", error);
        console.log("login with google failed");
    };


    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
                <Button
                    data-provider="google"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    variant="outlined"
                    fullWidth
                    startIcon={<img src={googleLogo} alt="icons"/>}
                >
                    Continue with Google
                </Button>
            )}
        />
    );
};

export default GoogleLoginComp;
