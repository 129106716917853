import {Box, Button, Typography} from "@mui/material";
import React, {FC, ReactHTMLElement, useEffect, useState} from "react";
import useAppSelector from "../../../hooks/useAppSelector";
import {useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";
import Vimeo from "@u-wave/react-vimeo";
import ScriptBar from "../../../components/CodeBlock/ScriptBar";
import SwipeableViews from "react-swipeable-views";
import {CodeBlock, CopyBlock, dracula} from "react-code-blocks";
import toast from "react-hot-toast";
import {StaticLanguages} from "../../../models/script";
import Markdown from "markdown-to-jsx";

interface Props {
    scriptContent?: {
        attributes: {
            Visibility: string;
            content: string;
            createdAt: Date;
            position: number;
            publishedAt: Date;
            scripts: {
                data: {
                    attributes: any;
                }[];
            };
            tab: string;
            title: string;
            unique_title: string;
            updatedAt: Date;
        };
    };
    currentIndex: {};
    token: string;
    setPaymentToken: Function;
    script: string | "" | {};
    setScript: Function;
    language: string;
    setLanguage: Function;
    videoLink: string;
    newScriptData: any
    setNewScriptData: Function
    value: number
    setValue: Function
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const NestedAppBar = styled(Box)`
  border-bottom: 3px solid #fba4a4;
  background: rgb(40, 42, 54);
  display: flex;
  padding: 20px 0;
`;

const NestedAppBarButtonWrapper = styled(Box)`
  background: rgb(40, 42, 54);
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
`

const NestedAppBarTitle = styled(Typography)`
  color: #fff;
  font-size: 14px;
  margin: 0 15px 0 30px;
  cursor: pointer;
`

const NestedAppBarButton = styled(Button)`
  background: floralwhite;
  color: darkblue;
  font-size: 14px;
  margin: 0 10px 15px 1px;
  width: 10%;
`

const scripts = [
    {
        id: "script1",
        text:
            "let url = '<url/>';\n" +
            'fetch(url, {method: "POST", body: JSON.stringify(payload)}).then(r => r.json())' +
            ".then(data => console.log(data)).catch(e => console.log('Error occurred', e.message))",
    },
]

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const ScriptContent: FC<Props> = ({
                                      scriptContent,
                                      currentIndex,
                                      language,
                                      setLanguage,
                                      script,
                                      newScriptData,
                                      value,
                                      setValue,
                                      setPaymentToken,
                                      token,
                                  }): any => {

    const [tabValue, setTabVal] = useState<number>(1);
    const purchase = useAppSelector((state) => state.purchaseReducer.purchase);
    const theme = useTheme();
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: number
    ): void => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => setValue(index)

    const handleTestScript = () => {
        let text1 = "const payload = " + script + ";\n" + scripts[0].text
        const url = `https://api.infinityweb.dev/${token}`
        text1 = text1.replaceAll("<url/>", url)
        // eslint-disable-next-line no-eval
        eval(text1)
    }

    useEffect(() => {
        if (token)
            setPaymentToken(token)
        else
            setPaymentToken("No Token Yet!")
    }, [token, currentIndex])

    useEffect(() => {
    }, [language, value, currentIndex]);

    const splitStringWithTags = () => {
        let str = scriptContent?.attributes?.content || ""
        return str?.split("\n").filter(txt => txt)
    }

    const getLang = (lang: string) => {
        switch (lang) {
            case "Curl":
            case "apps_script":
            case "zapier_js":
            case "airtable_script":
            case "node":
                return "js";
            case "zapier_py":
                return "python";
            default:
                return lang
        }
    }

    const box = (key: string | number) => <Box key={key}>
        <ScriptBar
            StaticLanguages={StaticLanguages}
            language={language}
            value={value}
            setLanguage={setLanguage}
            handleChange={handleChange}
            handleChangeIndex={handleChangeIndex}
            a11yProps={a11yProps}
        />
        <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
        >
            {/* Code Block: REQUEST */}
            <TabPanel value={value} index={0} dir={theme.direction}>
                <CopyBlock
                    language={getLang(language)}
                    text={newScriptData !== undefined ? script : ""}
                    showLineNumbers={true}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                    style={{padding: "0 !important"}}
                    onCopy={() => toast.success('Request Copied!')}
                />
            </TabPanel>
            {/* Code Block: RESPONSE */}
            <TabPanel value={value} index={1} dir={theme.direction}>
                <CodeBlock
                    language={getLang(language)}
                    text={newScriptData !== undefined ? script : ""}
                    showLineNumbers={true}
                    theme={dracula}
                    wrapLines={true}
                    codeBlock
                    style={{padding: "0 !important"}}
                />
            </TabPanel>
            {/* Code Block: TEST */}
            <TabPanel value={value} index={2} dir={theme.direction}>
                <NestedAppBar>
                    <NestedAppBarTitle onClick={() => setTabVal(1)}>
                        Payload
                    </NestedAppBarTitle>
                    <NestedAppBarTitle onClick={() => setTabVal(2)}>
                        Response
                    </NestedAppBarTitle>
                </NestedAppBar>
                {/*Test -> Payload */}
                {tabValue === 1 ? (
                    <React.Fragment>
                        <CopyBlock
                            language={getLang(language)}
                            text={newScriptData !== undefined ? script : ""}
                            showLineNumbers={true}
                            theme={dracula}
                            wrapLines={true}
                            codeBlock
                            style={{padding: "0 !important"}}
                        />
                        <NestedAppBarButtonWrapper>
                            <NestedAppBarButton
                                disableRipple={true}
                                onClick={() => handleTestScript()}
                            >
                                Test
                            </NestedAppBarButton>
                        </NestedAppBarButtonWrapper>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/*Test -> Response*/}
                        <CopyBlock
                            language={getLang(language)}
                            text={newScriptData !== undefined ? script : "Response"}
                            showLineNumbers={true}
                            theme={dracula}
                            wrapLines={true}
                            codeBlock
                            style={{padding: "0 !important"}}
                        />
                    </React.Fragment>
                )}
            </TabPanel>
        </SwipeableViews>
    </Box>

    const components = {
        a: ({node, ...props}) => {
            if (node.properties?.href === "token")
                return <CopyBlock language='text' showLineNumbers={false} theme={dracula} wrapLines={true}
                                  codeBlock={true} text={purchase && token ? token : "No token yet"}/>

            if (node.properties?.href?.startsWith('https://vimeo.com'))
                return <Vimeo autoplay className={'vimeo'} video={node.properties?.href as string}/>

            return <a children={node.children} href={node.properties.href}></a>
        },

        script: ({node, ...props}) => box(node.properties.id)
    }

    // return <div><p>{JSON.stringify(scriptContent?.attributes?.content)}</p></div>

    // return <ReactMarkdown className="markdown_container" linkTarget="_blank"
    //                       components={{s: () => {}} skipHtml={false} rehypePlugins={[rehypeRaw]}
    //                       remarkPlugins={[remarkGfm]}>{scriptContent?.attributes?.content || ''}</ReactMarkdown>

    return <Markdown options={{
        overrides: {
            token: {
                component: CopyBlock,
                props: {
                    language: 'text',
                    showLineNumbers: false,
                    theme: dracula,
                    wrapLines: true,
                    codeBlock: true,
                    text: purchase && token ? token : 'No token yet'
                }
            }
        },
        createElement: (tag, props, ...children) => {
            if (tag === 'script')
                return box(props.id)

            if (tag === 'vimeo' && children.length && Array.isArray(children[0]) && children[0].length)
                return <Vimeo video={(children[0][0] as ReactHTMLElement<HTMLAnchorElement>).props.href!} autoplay
                              className={'vimeo'}></Vimeo>

            return React.createElement(tag, props, children)
        }
    }
    }>{scriptContent?.attributes?.content || ''}</Markdown>
}

export default ScriptContent
