import styled from "@emotion/styled";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography} from "@mui/material";
import _ from "lodash";
import React, {FC, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import SolutionModel from "../../models/solution.model";
import URL from "../../services/Apis";
import {get} from "../../services/AxiosRequestMethods";
import SolutionCard from "../components/solutions/SolutionCard";
import {AppModel} from "../../models/app.model";
import utility from "../../utils/utility";

// emotions styling

const TextfieldWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const LeftFilterWrapper = styled(Grid)`
  display: flex;
  font-weight: bolder;
  cursor: pointer;
`

const FiltersWrapper = styled(Box)`
  transition-property: width;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
`

const SolutionsWrapper = styled(Grid)``

const NoSolutions = styled(Typography)`
  margin-top: 3rem;
`

// functional component
const Solutions: FC = () => {
    const [data, setData] = useState<SolutionModel[]>([])
    const [filterApps, setFilterApps] = useState<AppModel[]>([])
    const [filterClicked, setFilterClicked] = useState(false)
    const [filterBy, setFilterBy] = useState<string[]>([])
    const [searchTerm, setSearchTerm] = useState<string>("")
    const userToken = useAppSelector<string | undefined>((state) => state.userReducer.user?.token)
    const isLogin = useAppSelector((state) => state.userReducer.isLogin)
    const location = useLocation()


    // console.log('ss',process.env.REACT_GOOGLE_CLIENT_ID);
    // console.log('ss',process.env.REACT_APP_GOOGLE_AUTH0_CLIENT_ID);

    const getSolutions = () => {
        let queryParams = ""
        if (filterBy.length)
            queryParams = filterBy.map(f => `apps[]=${f}`).join('&')
        if (searchTerm.length)
            queryParams = `searchTerm=${searchTerm}&${queryParams}`

        get<{ data: SolutionModel[], pagination: { count: number, totalPages: number, pageSize: number, pageNumber: number } }>(`${URL.Solutions.getSolutions}?${queryParams}`, userToken).then((response) => {
            if (response) {
                setData(response.data)

                if (!searchTerm.length && !filterBy.length) {
                    // Update the values to show only when no filters are applied
                    const apps: AppModel[] = response.data.map(s => s.apps).flat(1)
                    setFilterApps(utility.distinct<AppModel>("id", apps))
                }
            }
        })
    }

    const searchSolutions = _.debounce(async function (e) {
        //get filtered data using api
        setSearchTerm(e.target.value)
    }, 500)

    const filterSolutions = async (e) => {
        const appCode = e.target.value;
        let checked = e.target.checked;
        console.log(`checked: ${checked}`)
        let filters = [...filterBy]
        if (checked)
            filters.push(appCode)
        else {
            let idx = filters.findIndex(value => value === appCode)
            if (idx !== -1)
                filters.splice(idx, 1)
        }
        setFilterBy(filters)
    };

    useEffect(() => {
        getSolutions();
    }, [searchTerm, filterBy]);

    return (
        <React.Fragment>
            {/* title and textfield */}
            <Grid container justifyContent="space-between" alignItems="center" mb={3}>
                <Grid item xs={12} sm={6} lg={6} xl={4}>
                    {location.pathname.includes('explore') ? (
                        <Typography variant="h2">{isLogin ? 'Find Solutions' : 'Find you solution'}</Typography>
                    ) : (
                        <Typography variant="h2">{isLogin ? 'Active Solutions' : 'Find you solution'}</Typography>
                    )}
                </Grid>
                <TextfieldWrapper item xs={12} sm={6} lg={6} xl={5}>
                    <TextField
                        variant="outlined"
                        onChange={searchSolutions}
                        placeholder="Quick search..."
                    />
                </TextfieldWrapper>
            </Grid>

            {/* filter */}
            <Grid container justifyContent="space-between" mb={3}>
                <LeftFilterWrapper item xs={12} sm={6} lg={6} xl={4}>
                    <FilterAltOutlinedIcon/>
                    <Typography sx={{marginLeft: 1}}>Filter by</Typography>
                    <Box onClick={() => setFilterClicked(!filterClicked)}>
                        <Typography sx={{marginLeft: 2, color: "#BDBDBD"}}>
                            All Apps
                        </Typography>
                    </Box>
                </LeftFilterWrapper>
            </Grid>

            {/* solution cards */}
            <SolutionsWrapper container gap={2}>
                <FiltersWrapper
                    width={filterClicked ? "150px" : "0px"}
                    zIndex={filterClicked ? 0 : -1}
                >
                    <FormGroup>
                        {filterApps.map(
                            (app, index) =>
                                <FormControlLabel
                                    key={index} label={app.name}
                                    control={
                                        <Checkbox value={app.appCode} onChange={(e) => filterSolutions(e)}/>
                                    }
                                />
                        )}
                    </FormGroup>
                </FiltersWrapper>
                {data &&
                    data?.map((solution: SolutionModel, index: number) => (
                        <Grid key={index} item xs={12} my={6} lg={5} xl={3}>
                            <SolutionCard data={solution}/>
                        </Grid>
                    ))}
                {data.length === 0 && (
                    <NoSolutions variant="h1">
                        No solutions found.
                    </NoSolutions>
                    // <CircularProgress />
                )}
            </SolutionsWrapper>
        </React.Fragment>
    );
};

export default Solutions;
