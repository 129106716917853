import styled from "@emotion/styled"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Fab,
    FormControl,
    Grid,
    InputLabel,
    Modal,
    OutlinedInput,
    Switch,
    Typography
} from "@mui/material"
import {isArray} from "lodash"
import React, {FC, useEffect, useState} from "react"
import Loader from "../../components/Loader"
import useAppSelector from "../../hooks/useAppSelector"
import {AppAccountModel, AppAccountPurchaseMap, AppModel} from "../../models/app.model"
import URL from "../../services/Apis"
import {del, get, post, put} from "../../services/AxiosRequestMethods"
import {getDate} from "./solutions/ProfileApps"
import {ExpandMore} from "@mui/icons-material"
import AddIcon from "@mui/icons-material/Add"
import ClearIcon from "@mui/icons-material/Clear"
import DoneIcon from "@mui/icons-material/Done"
import InsertLinkIcon from "@mui/icons-material/InsertLink"
import toast from "react-hot-toast"
import {useLocation} from "react-router-dom"
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace"
import PurchaseModel from "../../models/purchase.model"
import {PurchaseReducerType, UserReducer} from "../../redux/reducers/types"
import {useDispatch} from "react-redux"


interface Props {
    purchaseId?: number
    getData: (boolean) => void
    app: AppModel
    appAccount: AppAccountModel
    type?: string
}

const Img = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const connectAccount = async (appAccountId: number, purchaseId: number, token: string, isConnect: boolean) =>
    post<AppAccountPurchaseMap>(
        `${URL.AppAccount.ConnectAppAccount}/${purchaseId}/${isConnect ? "connect" : "disconnect"}`,
        {appAccountId},
        {
            headers: {
                Authorization: token
            }
        })

export const AppBox: FC<Props> = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const [appAccount, setAppAccount] = useState(props.appAccount)
    const loc = useLocation()
    const userReducer = useAppSelector((state) => state.userReducer)
    const [modalType, setModalType] = useState("")
    const [modalFlag, setModalFlag] = useState<boolean>(false)

    // todo when a connected switch is flicked in one app-box re-render all the app-boxes
    //    for this, this event needs to travel to the parent

    useEffect(() => {
        setModalFlag(false)
    }, [modalFlag])

    const renderFields = (): ReactJSXElement => {
        const fields: ReactJSXElement[] = []
        for (let i = 1; i < 7; i++) {
            const fieldLabel = props?.app[`field${i}`]
            if (fieldLabel)
                fields.push(
                    <FormControl key={i} fullWidth sx={{mt: 4}}>
                        <InputLabel htmlFor="component-outlined">
                            {props?.app[`field${i}`]}
                        </InputLabel>
                        <OutlinedInput
                            id="component-outlined"
                            label={fieldLabel}
                            defaultValue={
                                modalType === "save" ? "" : appAccount[`value${i}`]
                            }
                            onChange={(e) => handleChange(e, `value${i}`)}
                        />
                    </FormControl>
                )
        }

        return <Grid item xs={12}>{fields}</Grid>
    }

    const handleChange = (e, field) => {
        let value = e?.target?.value
        let temp = {...appAccount}

        temp[field] = value
        setAppAccount(temp)
    }

    const saveAccount = () => {
        const dataToSend = {
            appId: props?.app?.id,
            appCode: props?.app.appCode,
            name: appAccount.name,
            active: false,
        };
        for (let i = 1; i < 7; i++)
            if (appAccount[`value${i}`])
                dataToSend[`value${i}`] = appAccount[`value${i}`];

        post<void>(URL.AppAccount.CreateAppAccount, dataToSend, {
            headers: {
                Authorization: userReducer.user?.token,
            },
        }).then(() => {
            setOpenModal(false);
            props.getData(true);
        }).catch((err) => {
            console.error("save error", err);
        })
    }

    const updateAccount = () => {
        put(
            `${URL.AppAccount.EditAppAccountByID}/${appAccount?.id}`,
            appAccount,
            {
                headers: {
                    Authorization: userReducer.user?.token,
                },
            }
        ).then(() => {
            setOpenModal(false)
            props.getData(false)
            setModalFlag(true)
            connectAccount1(true)
        }).catch((err) => {
            console.error("save error", err)
        })
    }

    const deleteAccount = () => {
        del(
            `${URL.AppAccount.CreateAppAccount}/${appAccount?.id}`,
            userReducer.user?.token
        ).then(() => {
            setOpenModal(false)
            setModalFlag(true)
            props.getData(false)
        }).catch((err) => {
            console.error("save error", err)
        })
    }

    const connectAccount1 = (isConnect: boolean) => {
        connectAccount(appAccount.id!!, props.purchaseId!!, userReducer.user?.token!!, isConnect).then((_) => {
            setOpenModal(false)
            props.getData(false)
        }).catch((err) => {
            console.error("save error", err)
        })
    }

    const handleSaveClick = () => {
        if (!appAccount.name)
            return toast.error("Name is required")

        if (modalType === "update") updateAccount()
        else saveAccount()
    }

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => connectAccount1(e.target.checked)

    return (
        <Box sx={{mt: 8, p: 1, py: 5, borderBottom: "1px solid rgba(0,0,0,0.3)"}}>
            <Grid container>
                <Grid item sm={8}>
                    <Grid container>
                        {loc.pathname?.includes("/solutions") && (
                            <Grid item xs={2}>
                                <Switch
                                    checked={props.type === "connected"}
                                    onChange={handleSwitch}
                                />
                            </Grid>
                        )}
                        <Grid item xs={10}>
                            <Typography variant="h5">{props?.appAccount?.name}</Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    opacity: 0.5,
                                    mt: 2,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <InsertLinkIcon sx={{mr: 2}}/> Last updated on{" "}
                                {getDate(props?.appAccount?.updatedAt)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} display="flex" alignItems="center" direction="column">
                    {props.type && <Typography
                        variant="h6"
                        sx={{
                            mt: 2,
                            px: 3,
                            py: 1,
                            borderRadius: "10px",
                            border: "0.5px solid rgba(0,0,0,0.5)",
                            // bgcolor: props.type == "connected" ? "#45bf55" : "transparent",
                            bgcolor: props.type === "connected" ? "#45bf55" : "transparent",
                            color: props.type === "connected" ? "white" : "rgba(0,0,0,0.5)",
                        }}
                    >
                        {props.type === "connected" ? "Connected" : "Available"}
                    </Typography>}
                    <Typography
                        variant="h6"
                        sx={{opacity: 0.5, mt: 2, cursor: "pointer"}}
                        onClick={() => {
                            setOpenModal(true);
                            setModalType("update");
                        }}
                    >
                        Manage
                    </Typography>
                </Grid>
            </Grid>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: 600,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 8,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h2" component="h2">
                        {props?.appAccount?.name}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 4}}>
                                <InputLabel htmlFor="component-outlined">Name</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    label="Name"
                                    value={appAccount.name}
                                    onChange={(e) => handleChange(e, "name")}
                                />
                            </FormControl>
                        </Grid>
                        {renderFields()}
                    </Grid>
                    <Grid container spacing={1} sx={{mt: 4}} justifyContent="flex-end">
                        <Grid item sm={4}>
                            <Button
                                onClick={handleSaveClick}
                                variant="outlined"
                                sx={{color: "green"}}
                                fullWidth
                                startIcon={<DoneIcon/>}
                            >
                                Save
                            </Button>
                        </Grid>
                        {/* {loc.pathname?.includes('/solutions') && props.type == "available" && modalType === "update" && (
              <Grid item sm={4}>
                <Button
                  onClick={connectAccount}
                  variant="outlined"
                  sx={{ color: "black" }}
                  fullWidth
                  startIcon={<InsertLinkIcon />}
                >
                  Connect
                </Button>
              </Grid>
            )} */}
                        {modalType === "update" && (
                            <Grid item sm={4}>
                                <Button
                                    onClick={deleteAccount}
                                    variant="outlined"
                                    sx={{color: "red"}}
                                    fullWidth
                                    startIcon={<ClearIcon/>}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}

interface AppsComponentProps {
    appsData: AppModel[]
    purchaseId?: number
    status: boolean
}

function AppsComponent(props: AppsComponentProps) {
    const dispatch = useDispatch()
    const [apps, setApps] = useState<AppModel[]>([])
    const userReducer = useAppSelector<UserReducer>((state) => state.userReducer)
    const purchaseReducer = useAppSelector<PurchaseReducerType>((state) => state.purchaseReducer)

    const [selectedApp, setSelectedApp] = useState<AppModel>({
        id: -1,
        appCode: "",
        creator: -1,
        name: "",
        slug: "",
        appType: "commercial",
        descriptionPreview: "",
        descriptionLong: "",
        faviconUrl: "",
        iconUrl: "",
        active: false,
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
    });
    const [appAccounts, setAppAccounts] = useState<{
        appAccount: AppAccountModel,
        app: any,
        type?: string
    }[]>([]);
    const [appAccount, setAppAccount] = useState<AppAccountModel>({
        appId: -1,
        appCode: "",
        userId: userReducer.user?.id || 0,
        name: "",
        active: false
    })
    const [expandedId, setExpandedId] = useState<number>(-1)
    const [loading, setLoading] = useState<boolean>(false)
    const [modalType, setModalType] = useState("")
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        getAppsData()
    }, [props.appsData, appAccounts])

    const getAppsData = () => {
        setLoading(true)
        if (isArray(props.appsData))
            setApps(props.appsData)
        setLoading(false)
    }

    const getPurchase = (): Promise<PurchaseModel | undefined> => {
        if (!purchaseReducer.purchase)
            return Promise.resolve(undefined)

        return get<PurchaseModel | undefined>(`${URL.Purchases.getPurchaseByID}${purchaseReducer.purchase.id}`, userReducer.user?.token).then(res => {
                dispatch({
                    type: "PURCHASE_FETCHED",
                    payload: res,
                })

                if (!res)
                    return undefined

                if (Array.isArray(res) && res.length)
                    return res[0]
                else
                    return res
            }
        )
    }

    const getAppAccounts = async (app: AppModel, index: number, closingCheck: boolean, purchase?: PurchaseModel) => {
        if (closingCheck) {
            if (index === expandedId) {
                setExpandedId(-1)
                return;
            }
            setExpandedId(index);
            setLoading(true);
        }
        try {
            const p = purchase ?? purchaseReducer.purchase

            const appAccounts = await get<AppAccountModel[]>(
                `${URL.App.GetApps}/${app.id}/accounts`,
                userReducer.user?.token
            )
            // const purchased = await get<PurchaseModel[]>(URL.Purchases.getPurchases, userReducer.user?.token);

            let tempAppAccounts: {
                appAccount: AppAccountModel,
                app: any,
                type?: string
            }[] = appAccounts.map(aa => {
                const type = p?.appAccounts?.find(paa => paa.id === aa.id) ? `connected` : `available`

                return {
                    appAccount: aa,
                    app, type
                }
            })


            // if (isArray(appAccounts) && appAccounts.length) {
            //   // setAppAccounts(a)
            //   appAccounts.map((app_account) => {
            //     const app_acc = {
            //       appAccount: app_account,
            //       app: app,
            //       type: "available",
            //       purchased_id: payment?.id,
            //     };
            //     purchased?.length &&
            //       purchased?.map((purchase) => {
            //         purchase?.appAccounts?.map((purch_app) => {
            //           if (app_account?.id === purch_app?.id) {
            //             app_acc.type = "connected";
            //             app_acc.purchased_id = purchase?.id;
            //           }
            //         });
            //       });
            //     tempAppAcounts.push(app_acc);
            //   });
            // }
            setAppAccounts(tempAppAccounts)
            setLoading(false)
        } catch (err: any) {
            setAppAccounts([])
            setLoading(false)
        }
    }

    const renderFields = (): any | void => {
        const items: ReactJSXElement[] = []

        for (let i = 1; i < 7; i++) {
            let fieldLabel = selectedApp[`field${i}`]
            if (fieldLabel) {
                items.push(
                    <FormControl key={i} fullWidth sx={{mt: 4}}>
                        <InputLabel htmlFor="component-outlined">
                            {selectedApp[`field${i}`]}
                        </InputLabel>
                        <OutlinedInput
                            id="component-outlined"
                            label={fieldLabel}
                            defaultValue={
                                modalType === "save" ? "" : appAccount[`value${i}`]
                            }
                            onChange={(e) => handleChange(e, `value${i}`)}
                        />
                    </FormControl>)
            }
        }

        return <Grid item xs={12}>
            {items}
        </Grid>
    }

    const handleChange = (e, field) => {
        let value = e?.target?.value;
        let temp = {...appAccount};
        temp[field] = value;
        setAppAccount(temp);
    };

    const saveAccount = () => {
        const dataToSend = {
            appId: selectedApp.id,
            appCode: selectedApp.appCode,
            name: appAccount.name,
            active: false,
        }
        for (let i = 1; i < 7; i++)
            if (appAccount[`value${i}`])
                dataToSend[`value${i}`] = appAccount[`value${i}`];

        post<AppAccountModel>(URL.AppAccount.CreateAppAccount, dataToSend, {
            headers: {
                Authorization: `${userReducer.user?.token}`,
            },
        }).then((aam) => {
            if (purchaseReducer.purchase)
                return connectAccount(aam.id!!, purchaseReducer.purchase?.id!!, userReducer.user?.token!!, true)

            return undefined
        }).then((aapm) => {
            if (aapm)
                return getPurchase()
            return undefined
        }).then(() => {
            setOpenModal(false);
            return getAppAccounts(selectedApp, -1, false, purchaseReducer.purchase)
        }).then(() => {
            toast.success("App account has been added")
        }).catch((err: Error) => {
            console.error("save error", err);
        })
    }

    const handleSaveClick = () => {
        if (!appAccount.name) {
            toast.error("Name is required")
            return
        }
        saveAccount()
        setAppAccount({
            appId: -1,
            appCode: "",
            userId: userReducer.user?.id || 0,
            name: "",
            active: false
        })
    }

    return (
        <Box>
            {apps.map((app, index) => {
                return (
                    <Accordion key={index}
                               style={{marginBottom: "15px"}}
                               expanded={expandedId === index}
                    >
                        <AccordionSummary
                            expandIcon={
                                <ExpandMore onClick={() => getAppAccounts(app, index, true)}/>
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            // onClick={()=>getAppAccounts(app,index)}
                        >
                            <Typography
                                variant="h5"
                                sx={{display: "flex", alignItems: "center"}}
                            >
                                <Img src={app?.faviconUrl}/>
                                {app?.name}
                                {userReducer.isLogin && <Fab
                                    color="primary"
                                    aria-label="add"
                                    size="small"
                                    sx={{
                                        ml: 4,
                                        width: "30px",
                                        height: "30px",
                                        minHeight: "25px",
                                    }}
                                    onClick={() => {
                                        setSelectedApp(app);
                                        setOpenModal(true);
                                        setModalType("save");
                                    }}
                                >
                                    <AddIcon/>
                                </Fab>}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {loading ? (
                                <Loader/>
                            ) : appAccounts?.length ? (
                                appAccounts.map((appAcc, idx) => (
                                    <Box
                                        key={idx}
                                        style={{paddingLeft: "10px", paddingRight: "10px"}}
                                    >
                                        <AppBox
                                            purchaseId={props.purchaseId}
                                            getData={(closingCheck = true) => {
                                                getPurchase().then((p) => {
                                                    return getAppAccounts(app, index, closingCheck, p)
                                                }).then(() => {
                                                    console.log(`Data refreshed on the page.`)
                                                })
                                            }}
                                            app={appAcc.app}
                                            appAccount={appAcc.appAccount}
                                            type={props.status ? appAcc.type : undefined}
                                        />
                                    </Box>
                                ))
                            ) : (
                                <Typography textAlign="center">
                                    No app accounts found for this app
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: 600,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 8,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h2" component="h2">
                        Add an app account
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl fullWidth sx={{mt: 4}}>
                                <InputLabel htmlFor="component-outlined">Name</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    label="Name"
                                    value={appAccount.name}
                                    onChange={(e) => handleChange(e, "name")}
                                />
                            </FormControl>
                        </Grid>
                        {renderFields()}
                    </Grid>
                    <Grid container spacing={1} sx={{mt: 4}} justifyContent="flex-end">
                        <Grid item sm={4}>
                            <Button
                                onClick={handleSaveClick}
                                variant="outlined"
                                sx={{color: "green"}}
                                fullWidth
                                startIcon={<DoneIcon/>}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    );
}

export default AppsComponent
