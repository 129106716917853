import styled from "@emotion/styled";
import {AppBar, Box, Grid, MenuItem, Select, Tab, Tabs, Typography,} from "@mui/material";
import React, {FC} from "react";
import {Ilang} from "../../models/script";

interface Props {
  StaticLanguages: [] | any;
  language: {};
  value: number;
  setLanguage: Function;
  handleChange: (e: React.SyntheticEvent, num: number) => void;
  handleChangeIndex: Function;
  a11yProps: Function;
}

const TopWrapper = styled(Box)`
  background: #3c4257;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
const Title = styled(Box)`
  width: 100%;
`;
const ScriptsTab = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
  border: none;
  outline: none;
`;

const ScriptBar: FC<Props> = ({
  StaticLanguages,
  language,
  setLanguage,
  handleChange,
  a11yProps,
  value,
}) => {

  return (
    <React.Fragment>
      <TopWrapper>
        <ScriptsTab>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs={12} sm={8}>
              <Title>
                <AppBar
                  position="static"
                  sx={{ background: "transparent", color: "#fff" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Request" {...a11yProps(0)} />
                    <Tab label="Response" {...a11yProps(1)} />

                    {/* 
                      Test Tab: Block temporarily.
                     */} 
                    {/* <Tab label="Test" {...a11yProps(2)} /> */}

                  </Tabs>
                </AppBar>
              </Title>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                value={language}
                onChange={(event: any) => {
                  setLanguage(event?.target?.value);
                }}
                sx={{
                  display: "flex",
                  border: "none",
                  outline: "none",
                  color: "#c1c9d2",
                  "&:hover": {
                    border: "none",
                    outline: "none",
                  },
                }}
              >
                {StaticLanguages?.map((language: Ilang, index: number) => {
                  return (
                    <MenuItem key={index} value={language.lang}>
                      <Box sx={{ padding: "0 5px 4px 5px", display: "flex" }}>
                        <img src={language.icon} alt={language.lang} />
                        <Typography sx={{ marginLeft: "5px" }}>
                          {language.title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>   
        </ScriptsTab>
      </TopWrapper>
    </React.Fragment>
  );
};

export default ScriptBar;
