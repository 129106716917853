import styled from "@emotion/styled";
import {Box, Button} from "@mui/material";
import GitHubLogin from "react-github-login";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import githubLogo from "../../assets/icons/Octicons-mark-github-1.svg";
import URL from "../../services/Apis";
import {post} from "../../services/AxiosRequestMethods";
import GoogleLoginComp from "./GoogleLogin";

const SocialMediaLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSuccess = async (response: string[] | any) => {

        const data = {
            provider: "github",
            idToken: response?.code,
        };

        if (response.code) {
            const userData = await post(URL.Users.login, data);
            navigate("/solutions");
            dispatch({
                type: "LOGIN",
                payload: userData,
            });
        }
    };
    const onFailure = (err: Error) => console.error({err});
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box sx={{width: "75%"}}>
                <GoogleLoginComp/>
            </Box>
            <Box
                sx={{
                    margin: "1.5rem 0",
                    width: "75%",
                    border: "1px solid rgba(55, 111, 208, 0.5)",
                    borderRadius: "4px",
                }}
            >
                <StyledButton
                    variant="outlined"
                    fullWidth
                    startIcon={<img src={githubLogo} alt="icons"/>}
                    sx={{
                        background: "transparent",
                        border: "none !important",
                        color: "#376fd0",
                        cursor: 'pointer'
                    }}
                >
                    <GitHubLogin
                        clientId={process.env.REACT_APP_GITHUB_CLIENT_ID}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        redirectUri="https://matrix.infinityweb.app/callback/github"
                        buttonText="Continue with GitHub"
                    />
                </StyledButton>
            </Box>
        </Box>
    );
};

const StyledButton = styled(Button)`
  & > button {
    border: none;
    background: transparent;
    color: #376fd0;
  }
`;

export default SocialMediaLogin;
